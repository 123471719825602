import React, { useState } from "react";
import Select from "react-select";
import Layout from "../../components/Layout";

const BookingDetail = () => {
  const bookingData = [{}, {}, {}];
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: "pending", label: "Pending" },
    { value: "process", label: "Process" },
    { value: "deliver", label: "Deliver" },
  ];

  return (
    <Layout pageTitle="Booking Detail">
      <div className="booking-page min-h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="flex items-center justify-between gap-3">
            <div className="flex flex-col flex-1 gap-2">
              <div className="flex items-center gap-1">
                <div className="bg-themeColor h-6 w-6 rounded-md" />
                <p className="text-[#A7A3A0] font-normal text-sm">
                  Date of Service : 2024-03-11
                </p>
              </div>
              <div className="flex items-center gap-1">
                <div className="bg-themeColor h-6 w-6 rounded-md" />
                <p className="text-[#A7A3A0] font-normal text-sm">
                  Payment Method : Pay On Service
                </p>
              </div>
            </div>
            <div className="flex flex-[1.4] gap-2 items-end">
              <div className="flex flex-col w-full">
                <h1 className="text-[#8120F] font-medium text-lg !w-max">
                  Booking Status
                </h1>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  className="w-full"
                />
              </div>
              <button className="button bg-themeColor w-[200px] h-max text-sm !py-2 rounded-md">
                Update Status
              </button>
            </div>
          </div>
          <div className="home-grid w-full grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 my-8">
            <div className="shadow-md-custom bg-white rounded-2xl overflow-hidden">
              <div className="responsive-table1 overflow-x-auto mb-20">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                        Service
                      </th>
                      <th className="px-2 py-2 text-left w-56 text-sm text-black font-medium">
                        Price
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Quantity
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Sub Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {bookingData.map((item, index) => (
                      <tr
                        key={index}
                        className={`p-2 ${
                          index % 2 === 0 ? "bg-white" : "bg-white"
                        }`}
                      >
                        <td className="px-2 py-2 text-sm text-black font-medium">
                          Tamsirh
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                          5 OMR
                        </td>
                        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                          1
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                          5.250 OMR
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex items-center justify-between gap-3 bg-[#F5F7FB] px-3 py-2 w-full">
                <p className="text-black text-sm font-normal">
                  Co %: 0.250 OMR
                </p>
                <p className="text-black text-sm font-normal">
                  <span className="font-semibold">Total</span> 5.250 OMR
                </p>
              </div>
            </div>
            <div className="shadow-md-custom flex flex-col bg-white rounded-2xl p-3">
              <h2 className="text-black text-lg font-medium ">
                Customer Details
              </h2>
              <div className="flex flex-col gap-2 mt-4">
                <p className="text-[#808080] text-sm font-normal">Name:</p>
                <p className="text-[#808080] text-sm font-normal">
                  Contact Number:
                </p>
                <p className="text-[#808080] text-sm font-normal">Email:</p>
                <p className="text-[#808080] text-sm font-normal">Address:</p>
              </div>
            </div>
            <div className="shadow-md-custom flex flex-col bg-white rounded-2xl p-3">
              <h2 className="text-black text-lg font-medium ">Service Info</h2>
              <div className="flex flex-col gap-2 mt-4">
                <p className="text-[#808080] text-sm font-normal">
                  Start Time:
                </p>
                <p className="text-[#808080] text-sm font-normal">End Time:</p>
                <p className="text-[#808080] text-sm font-normal">Email:</p>
                <p className="text-[#808080] text-sm font-normal">
                  Service Duration:
                </p>
                <p className="text-[#808080] text-sm font-normal">
                  Visiting Charge:
                </p>
                <p className="text-[#808080] text-sm font-normal">
                  Tmsir type:
                </p>
                <p className="text-[#808080] text-sm font-normal">
                  Tmsir Color:
                </p>
                <p className="text-[#808080] text-sm font-normal">Accessory:</p>
              </div>
            </div>
            <div className="shadow-md-custom flex flex-col bg-white rounded-2xl p-3">
              <h2 className="text-black text-lg font-medium ">Store Info</h2>
              <div className="flex flex-col gap-2 mt-4">
                <p className="text-[#808080] text-sm font-normal">Provider:</p>
                <p className="text-[#808080] text-sm font-normal">
                  Company Name:
                </p>
                <p className="text-[#808080] text-sm font-normal">Email:</p>
                <p className="text-[#808080] text-sm font-normal">Phone:</p>
                <p className="text-[#808080] text-sm font-normal">Address:</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookingDetail;
