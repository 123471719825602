import React, { useEffect, useState } from "react";
import { CrossIcon, EditIcon } from "../assets/Icons";
import { useSelector } from "react-redux";
import { routes } from "../Api/routes";
import { getRequest, postRequest } from "../Api";
import { toast } from "react-toastify";
import AppLoader from "./Loader/AppLoader";

const AdSettingsModel = ({ loading, setLoading, openModal, setOpenModal }) => {



  const words = useSelector((state) => state.authReducer.words);

  const [priceMonth, setPriceMonth] = useState(0)
  const [price3Months, setPrice3Months] = useState(0)
  const [price6Months, setPrice6Months] = useState(0)
  const [priceYear, setPriceYear] = useState(0)

  const doAddSetting = async () => {

    if (priceMonth == 0) return toast.error("Please enter Price/Month");
    if (price3Months == 0) return toast.error("Please enter Price/3 Month");
    if (price6Months == 0) return toast.error("Please enter Price/6 Month");
    if (priceYear == 0) return toast.error("Please enter Price/Year");


    const onSuccess = (res) => {
      setLoading(false);
      toast.success("Ad duration updated successfully");
      setOpenModal(false);
      return;
    };
    const onError = (err) => {
      console.log("errororor", err);
      setLoading(false);
      toast.error(err?.error);
    };

    const body = {
      durations: [
        {
          duration_number: 1,
          price: priceMonth
        },
        {
          duration_number: 3,
          price: price3Months
        },
        {
          duration_number: 6,
          price: price6Months
        },
        {
          duration_number: 12,
          price: priceYear
        },

      ]
    };





    setLoading(true);
    await postRequest(body, routes.updateAdDuration, true, onSuccess, onError);
  };


  const getAdDuration = async () => {
    const onSuccess = (res) => {
      const data = res.durations;
      try {


        setPriceMonth(data[0]?.price)
        setPrice3Months(data[1]?.price)
        setPrice6Months(data[2]?.price)
        setPriceYear(data[3]?.price)
      }
      catch (err) { }
    }
    const onError = (err) => {

    }
    setLoading(true);
    await getRequest('', routes.getAdsDuration, true, onSuccess, onError)
    setLoading(false);


  }

  useEffect(() => {
    getAdDuration()
  }, [])



  return (
    <>
      {
        loading && <AppLoader />
      }
      <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
        <div className="flex items-center justify-between gap-3">
          <h1 className="text-black text-xl font-medium">{words["Ad settings"]}</h1>
          <div
            className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
            onClick={() => setOpenModal(false)}
          >
            <CrossIcon />
          </div>
        </div>

        <div className="flex flex-col gap-5 mt-10">
          {/* <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">Tax</label>
          <input
            type="text"
            placeholder="3 %"
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div> */}
          <div className="flex flex-col gap-2">
            <label className="text-black text-sm font-normal">{words["Price/Month"]} </label>
            <input
              onChange={(v) => setPriceMonth(v.target.value)}

              value={priceMonth}
              type="text"
              // placeholder="5"
              className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-black text-sm font-normal">
              {words["Price/3 Months"]}
            </label>
            <input
              value={price3Months}
              onChange={(v) => setPrice3Months(v.target.value)}
              type="text"
              // placeholder="15"
              className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-black text-sm font-normal">
              {words["Price/6 Months"]}
            </label>
            <input
              value={price6Months}
              onChange={(v) => setPrice6Months(v.target.value)}
              type="text"
              // placeholder="30 "
              className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-black text-sm font-normal">{words["Price/Year"]}</label>
            <input
              value={priceYear}
              onChange={(v) => setPriceYear(v.target.value)}
              type="text"
              // placeholder="60"
              className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
            />
          </div>
          <div
            onClick={() => doAddSetting()}
            className="flex items-center justify-center">
            <button className="button rounded-full !py-2 !px-10">{words["Save"]}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdSettingsModel;
