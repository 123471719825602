import React, { useState } from "react";
import { CrossIcon, ImageIcon } from "../assets/Icons";

const NewsDetailsModel = ({ openModal, setOpenModal }) => {
  const [img, setImg] = useState();

  return (
    <div className="flex flex-col shadow-md-custom border border-white">
      <div className="flex items-center justify-between gap-3 bg-[#F5F7FB] p-2">
        <h1 className="text-black text-base font-medium">News details</h1>
        <div
          className="flex items-center justify-center h-6 w-6 bg-white rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex flex-col gap-5 p-4">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-3">
            <img
              src="/images/user1.png"
              className="h-10 w-10 rounded-full object-contain bg-gray-300"
            />
            <h4 className="text-[#8E8E8F] text-xs font-light">
              Sadd salim · Feb 28,2023
            </h4>
          </div>
          <div className="flex items-center justify-center text-white text-[10px] font-light bg-[#3050FF] py-1 px-2 rounded-full">
            Business
          </div>
        </div>
        <h1 className="text-black text-lg">
          Everything you need for your restaurant in one place.
        </h1>
        <img
          src="/images/new-detail-bg.png"
          className=" bg-gray-300 rounded-xl h-[200px] w-full object-cover"
        />
        <p className="text-[#292D32] text-xs font-light">
          A year’s worth of rain in 24 hours submerged Dubai, a city in the
          desert proud of its modern gloss, in 142mm of water on April 16. The
          monthly average for the city in April is only about 8mm.
          <br />
          <br />
          Two Russian journalists have been arrested on “extremism” charges and
          ordered by courts there to remain in custody pending investigation and
          trial on accusations of working for a group founded by the late
          Russian opposition politician Alexei Navalny.
          <br />
          <br />
          Konstantin Gabov and Sergey Karelin both denied the charges for which
          they will be detained for a minimum of two months before any trials
          begin. Each faces a minimum of two years in prison and a maximum of
          six years for alleged “participation in an extremist organisation”,
          according to Russian courts.
        </p>
        <div className="flex flex-col gap-3">
          <label className="text-black text-sm font-normal">Note</label>
          <textarea
            className="min-h-[120px] max-h-[120px] border border-[#D8D8D8] rounded-lg p-2 focus:border-black anim"
            placeholder="Note...."
          />
        </div>
        <div className="flex items-center justify-end gap-4">
          <button className="btn bg-[#FF2C44]/20 text-[#FF2C44] font-medium text-sm rounded-full">
            Rejected
          </button>
          <button className="btn bg-[#9C8579]/20 text-[#9C8579] font-medium text-sm rounded-full">
            Re-review
          </button>
          <button className="btn bg-[#0DB214]/20 text-[#0DB214] font-medium text-sm rounded-full">
            Publishing
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsDetailsModel;
