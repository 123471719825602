import React from "react";

function NotesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        stroke="#0066A0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M63.175 30.45l-2.858 12.192c-2.45 10.529-7.292 14.787-16.392 13.912-1.458-.117-3.033-.379-4.725-.787L34.3 54.6c-12.162-2.888-15.925-8.896-13.067-21.087l2.859-12.221c.583-2.48 1.283-4.638 2.158-6.417 3.413-7.058 9.217-8.954 18.958-6.65l4.871 1.138C62.3 12.22 66.033 18.258 63.175 30.45z"
      ></path>
      <path
        stroke="#0066A0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M43.925 56.554c-1.808 1.225-4.083 2.246-6.854 3.15l-4.608 1.517c-11.58 3.733-17.675.612-21.438-10.967l-3.733-11.52c-3.734-11.58-.642-17.705 10.937-21.438l4.608-1.517c1.196-.379 2.334-.7 3.413-.904-.875 1.78-1.575 3.938-2.158 6.417l-2.859 12.22c-2.858 12.192.904 18.2 13.067 21.088l4.9 1.167c1.692.408 3.267.67 4.725.787zM36.867 24.88l14.146 3.587M34.008 36.167l8.459 2.158"
      ></path>
    </svg>
  );
}

export default NotesIcon;
