import React from "react";

function ImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      fill="none"
      viewBox="0 0 45 45"
    >
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M16.875 18.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
      ></path>
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M24.375 3.75h-7.5C7.5 3.75 3.75 7.5 3.75 16.875v11.25C3.75 37.5 7.5 41.25 16.875 41.25h11.25c9.375 0 13.125-3.75 13.125-13.125V18.75"
      ></path>
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeWidth="3"
        d="M29.531 9.375h10.313M34.688 14.531V4.22"
      ></path>
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M5.006 35.531l9.244-6.206c1.481-.994 3.619-.881 4.95.262l.619.544c1.462 1.256 3.825 1.256 5.287 0l7.8-6.694c1.463-1.256 3.825-1.256 5.288 0l3.056 2.625"
      ></path>
    </svg>
  );
}

export default ImageIcon;
