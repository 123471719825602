import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { DotMenuIcon } from "../assets/Icons";
import { Menu, Transition } from "@headlessui/react";

const MenuDropDown = ({ menuList }) => {
  const userNavigation = [
    { name: "View Booking", href: "#" },
    { name: "Invoice", href: "#" },
    { name: "Delete", href: "#" },
  ];

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>
        <div className="flex items-center justify-center cursor-pointer">
          <DotMenuIcon />
        </div>
        <span className="hidden lg:flex lg:items-center">
          <span
            className="ml-4 flex flex-col text-start text-sm font-semibold leading-6 text-gray-900"
            aria-hidden="true"
          ></span>
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {menuList.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) => <>{item.item}</>}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MenuDropDown;
