import React, { useState, useEffect } from "react";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import Modal from "../../components/Modal";
import SearchBar from "../../components/SearchBar";
import AddAdModel from "../../components/AddAdModel";
import { useSelector } from "react-redux";

const AdsTable = () => {
  const words = useSelector((state) => state.authReducer.words);
  const [openModal, setOpenModal] = useState(false);
  const storeData = [
    {
      title: "New car model 2024",
      type: "Ad",
      date: "21/11/2023",
      category: "Business",
      id: "#A1425",
      status: "Active",
    },
    {
      title: "New car model 2020",
      type: "Post",
      date: "21/11/2023",
      category: "Business",
      id: "#A1425",
      status: "Active",
    },
    {
      title: "Car number 740/A",
      type: "Post",
      date: "21/11/2023",
      category: "Business",
      id: "#A1425",
      status: "Active",
    },
    {
      title: "New car model 2024",
      type: "Ad",
      date: "21/11/2023",
      category: "Business",
      id: "#A1425",
      status: "Active",
    },
    {
      title: "New car model 2020",
      type: "Post",
      date: "21/11/2023",
      category: "Business",
      id: "#A1425",
      status: "Active",
    },
    {
      title: "Car number 740/A",
      type: "Post",
      date: "21/11/2023",
      category: "Business",
      id: "#A1425",
      status: "Active",
    },
  ];

  const NewsTableRow = ({ item, index }) => {
    const words = useSelector((state) => state.authReducer.words);
    const [hide, setHide] = useState(false);
    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);
    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-2 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center">
            <img
              src="/images/post-img.png"
              alt="userImage"
              className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
            />
          </div>
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.title}
        </td>

        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.date}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.category}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center gap-2">
            <img
              src="/images/user1.png"
              alt="userImage"
              className="h-8 w-8 rounded-full object-contain bg-gray-300"
            />
            {words["Ali ahamd"]}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-2 rounded-full text-center font-semibold ${
              item.status === "Active"
                ? "bg-[#0DB214]/40 text-[#0DB214]"
                : item.status === "Review"
                ? "bg-[#FFDF9F] text-[#FFAA01]"
                : "bg-red-200 text-red-400"
            }`}
          >
            {item.status}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#0025F2] relative">
          <div
            className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
          >
            <DotMenuIcon />
          </div>
          <div
            className={`menuList-block flex items-center absolute !bg-transparent !border-none !shadow-none !top-[20px] !left-[-30px] ${
              hide ? "show" : ""
            }`}
          >
            <div className="menu-item flex items-center flex-col anim !gap-2">
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#9C8579] !p-0 !border-none"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <EditIcon3 />
              </div>
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#6DBCC3] !p-0 !border-none"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <EyeIcon2 />
              </div>
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#FF5A5D] !p-0"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex items-center justify-between gap-3">
        <div className="flex flex-1">
          <SearchBar />
        </div>
        <div className="flex flex-1 justify-end">
          <button
            onClick={(e) => setOpenModal(true)}
            className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
          >
            <div className="flex items-center justify-center text-white font-normal text-2xl">
              +
            </div>
            <label className="text-white cursor-pointer">{words["Add"]}</label>
          </button>
        </div>
      </div>

      <div className="responsive-table overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-[#F5F7FB]">
            <tr>
              <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                {words["No"]}
              </th>
              <th className="px-2 py-2 text-left  text-sm text-black font-medium">
                {words["Image"]}
              </th>
              <th className="px-2 py-2 text-left w-56 text-sm text-black font-medium">
                {words["Title"]}
              </th>

              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["Date"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["Category"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["ID"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["Publisher"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["Status"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["Action"]}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {storeData.map((item, index) => (
              <NewsTableRow item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div>
      <Modal open={openModal} setOpen={setOpenModal}>
        <AddAdModel openModal={openModal} setOpenModal={setOpenModal} />
      </Modal>
    </div>
  );
};

export default AdsTable;
