import React from "react";

function IncomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M27.708 40.104c0 2.83 2.188 5.105 4.871 5.105h5.483c2.334 0 4.23-1.984 4.23-4.463 0-2.654-1.167-3.617-2.888-4.23l-8.779-3.062c-1.72-.612-2.887-1.546-2.887-4.229 0-2.45 1.895-4.462 4.229-4.462h5.483c2.683 0 4.87 2.275 4.87 5.104M35 21.875v26.25"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M64.167 35C64.167 51.1 51.1 64.167 35 64.167S5.833 51.1 5.833 35 18.9 5.834 35 5.834"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M49.583 8.75v11.667H61.25M64.167 5.833L49.583 20.418"
      ></path>
    </svg>
  );
}

export default IncomeIcon;
