import React, { useState, useEffect } from "react";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { routes } from "../../Api/routes";
import { postRequest } from "../../Api";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";
import { Dropdown, Menu, Button, Typography } from "antd";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import AppLoader from "../../components/Loader/AppLoader";
import Modal from "../../components/Modal";
import AddPostModel from "../../components/AddPostModal";
import RejectedReasonModel from "../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { Image } from 'antd';
import { useNavigate } from "react-router-dom";

const NewsTable = ({ loadingState = () => { }, data, refreshData = () => { } }) => {
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const [rejectedAd, setRejectedAd] = useState();
  const [rejectedAdModel, setRejectedAdModel] = useState(false);

  const columns = [
    {
      title: words["id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Title"],
      dataIndex: "title",
      key: "title",
    },
    {
      title: words["Category"],
      dataIndex: "category",
      key: "category",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {words[record.category]}
        </div>
      ),
    },
    {
      title: words["Status"],
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <div
          className={`py-2 px-0 rounded-full text-center font-semibold ${record.status?.toLowerCase() === "published"
            ? "bg-[#0DB214]/40 text-[#0DB214]"
            : "bg-red-200 text-red-400"
            }`}
        >
          {words[capitalizeFirstLetter(record.status)]}
        </div>
      ),
    },
    {
      title: words["Created at"],
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == 'view') {
                  navigate('/dashboard/post-details/' + record.id);
                  return;
                }
                if (key == 'rejected') {
                  setRejectedAd(record);
                  setRejectedAdModel(true);
                  return;
                }
                doChangePostStatus(key, record);
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
                <Menu.Item key="rejected">{words["Reject"]}</Menu.Item>
                <Menu.Item key="published">{words["Published"]}</Menu.Item>
                <Menu.Item key="in-review">{words["In-Review"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];


  // const columns = [
  //   {
  //     title: words["id"],
  //     dataIndex: "id",
  //     key: "id",
  //   },
  //   {
  //     title: words["Images"],
  //     dataIndex: "image",
  //     key: "image",
  //     render: (_, record) => (
  //       <div className="flex items-center">
  //         <Image
  //           style={{width:60,height:60}}
  //           src={IMAGE_URL + record.image}
  //           alt={record.image}
  //           className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
  //         />
  //       </div>
  //     ),
  //   },
  //   {
  //     title: words["Title"],
  //     dataIndex: "title",
  //     key: "title",
  //   },

  //   {
  //     title: words["Description"],
  //     dataIndex: "description",
  //     key: "description",
  //   },
  //   {
  //     title: words["Category"],
  //     dataIndex: "category",
  //     key: "category",
  //     render: (_, record) => (
  //       <div
  //       className="px-2 py-2 text-left text-sm text-black font-medium"
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         alignItems: "center",
  //       }}
  //       >
  //         {words[record.category]}
  //       </div>
  //     ),
  //   },
  //   {
  //     title: words["Status"],
  //     dataIndex: "status",
  //     key: "status",
  //     render: (_, record) => (
  //       <div
  //         className={`py-2 px-0 rounded-full text-center font-semibold ${record.status?.toLowerCase() === "published"
  //           ? "bg-[#0DB214]/40 text-[#0DB214]"
  //           : "bg-red-200 text-red-400"
  //           }`}
  //       >
  //         {capitalizeFirstLetter(record.status)}
  //       </div>
  //     ),
  //   },
  //   {
  //     title: words["Rejected reason"],
  //     dataIndex: "rejected_reason",
  //     key: "rejected_reason",
  //   },

  //   {
  //     title: words["Created at"],
  //     dataIndex: "created_at",
  //     key: "created_at",
  //     render: (_, record) => (
  //       <div
  //         className="px-2 py-2 text-left text-sm text-black font-medium"
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           alignItems: "center",
  //         }}
  //       >
  //         {getDateString(record.created_at)}
  //       </div>
  //     ),
  //   },
  //   {
  //     title: words["Action"],
  //     key: "action",
  //     render: (_, record) => (
  //       <Dropdown
  //         overlay={
  //           <Menu
  //             onClick={({ key }) => {
  //               if (key == "rejected") {
  //                 setRejectedAd(record);
  //                 setRejectedAdModel(true);
  //                 return;
  //               }
  //               doChangePostStatus(key, record);
  //             }}
  //           >
  //             <>
  //               <Menu.Item key="rejected">{words["Reject"]}</Menu.Item>
  //               <Menu.Item key="published">{words["Published"]}</Menu.Item>
  //               <Menu.Item key="in-review">{words["In-Review"]}</Menu.Item>
  //             </>
  //           </Menu>
  //         }
  //         trigger={["click"]}
  //       >
  //         <Button>
  //           <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
  //             <DotMenuIcon />
  //           </div>
  //         </Button>
  //       </Dropdown>
  //     ),
  //   },
  // ];
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const doChangePostStatus = async (status, record, reason = null) => {
    setRejectedAdModel(false);
    const onSuccess = (res) => {
      // setLoading(false);
      loadingState(false)
      refreshData();
      toast.success("Post status updated!")

      // getDashboard();
    };

    const onError = (err) => {
      // setLoading(false);
      toast.error(typeof err == 'string' ? err : "Something went wrong! Please try again later")
      loadingState(false)
      setLoading(false);
      toast.error(err?.error);
    };

    const body = {
      status: status,
      id: record.id,
    };
    if (status == "rejected") body.rejected_reason = reason;
    // setLoading(true);
    loadingState(true)
    await postRequest(body, routes.updatePostStatus, true, onSuccess, onError);
  };

  return (
    <>
      {loading && <AppLoader />}

      <div className="flex flex-col w-full gap-4">
        <div className="flex items-center justify-between gap-3">
          {/* <div className="flex flex-1">
          <SearchBar />
        </div> */}
          {isAdmin?.type == "admin" && (
            <div className="flex flex-1 justify-end">
              <div
                style={{ zIndex: 1 }}
                onClick={() => setOpenModal(true)}
                className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
              >
                <div className="flex items-center justify-center text-white font-normal text-2xl">
                  +
                </div>
                <label className="text-white cursor-pointer">{words["Add"]}</label>
              </div>
            </div>
          )}


        </div>

        <div style={{ marginTop: isAdmin?.type == "admin" && -50 }}>
          <DataTable
            searchContainerStyle={{ width: "40%" }}
            data={data}
            columns={columns}
          />
        </div>

        <Modal open={openModal} setOpen={setOpenModal}>
          <AddPostModel
            onSuccessCreate={() => refreshData()}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Modal>

        <Modal open={rejectedAdModel} setOpen={setRejectedAdModel}>
          <RejectedReasonModel
            openModal={rejectedAdModel}
            setOpenModal={setRejectedAdModel}
            onSubmit={(reason) =>
              doChangePostStatus("rejected", rejectedAd, reason)
            }
          />
        </Modal>
      </div>
    </>
  );
};

export default NewsTable;
