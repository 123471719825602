import React from "react";
import { SearchIcon, SearchSettingIcon } from "../assets/Icons";
import { useSelector } from "react-redux";

const SearchBar = ({ onChange = () => { } }) => {
  // const words = useSelector((state) => state.authReducer.words)
  return (
    <div className="flex items-center gap-3 w-full rounded-full border border-[#D1D5DB] px-3 py-2 focus-within:border-themeColor anim">
      <div className="flex items-center justify-center h-5 w-5">
        <SearchIcon />
      </div>
      <input
        onChange={(v) => onChange(v.target.value)}
        type="text"
        placeholder={"Search"}
        className="w-full cleanbtn text-base font-normal"
      />
      <div className="flex items-center justify-center h-5 w-5">
        <SearchSettingIcon />
      </div>
    </div>
  );
};

export default SearchBar;
