import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown, Menu, Button, Typography } from "antd";

import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import BannerModel from "../../components/BannerModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";

import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import AppLoader from "../../components/Loader/AppLoader";
import Modal from "../../components/Modal";
import AddPostModel from "../../components/AddPostModal";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";

import DataTable from "../../components/DataTable";
import EditBanner from "../../components/EditBanner";
import { useSelector } from "react-redux";

const Banner = () => {
  const words = useSelector((state) => state.authReducer.words);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editModalItem, setEditModalItem] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: words["id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Image"],
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <div className="flex items-center">
          <img
            src={IMAGE_URL + record.image}
            alt={record.image}
            className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
          />
        </div>
      ),
    },
    {
      title: words["url"],
      dataIndex: "url",
      key: "url",
    },

    {
      title: words["Start date"],
      dataIndex: "start_date",
      key: "start_date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.start_date)}
        </div>
      ),
    },
    {
      title: words["End date"],
      dataIndex: "end_date",
      key: "end_date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.end_date)}
        </div>
      ),
    },
    {
      title: words["Created at"],
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "edit") {
                  setEditModalItem(record);
                  setEditModal(true);
                }
                if (key == "delete") {
                  setDeletedId(record.id);
                  setOpenDeleteModal(true);
                }
              }}
            >
              <>
                <Menu.Item key="edit">{words["Edit"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const getBanners = async () => {
    const onSuccess = (res) => {
      console.log("res", res.data);
      setBanners(res.data);
      setLoading(false);
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest("", routes.getBanners, true, onSuccess, onError);
  };

  const doDeleteBanner = async () => {
    const onSuccess = (res) => {
      setDeletedId("");
      getBanners();
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest(
      { id: deletedId },
      routes.deleteBanner,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getBanners();
  }, []);

  const data = [
    {
      title: "iPhone 15 Pro wanted",
      url: "hppt:/www.emtnan/coupon",
      startDate: "21/11/2023",
      endDate: "21/12/2023",
      status: "Active",
    },
    {
      title: "Apartment wanted in Muscat",
      url: "hppt:/www.emtnan/coupon",
      startDate: "21/11/2023",
      endDate: "21/12/2023",
      status: "Active",
    },
    {
      title: "Double car number required",
      url: "hppt:/www.emtnan/coupon",
      startDate: "21/11/2023",
      endDate: "21/12/2023",
      status: "Active",
    },
    {
      title: "Nissan engine wanted",
      url: "hppt:/www.emtnan/coupon",
      startDate: "21/11/2023",
      endDate: "21/12/2023",
      status: "Inactive",
    },
  ];
  const TableRow = ({ item, index }) => {
    const [isOn, setIsOn] = useState(false);
    const [hide, setHide] = useState(false);
    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);
    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-2 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <img src="/images/banner-img.png" className="h-12 w-28" />
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.title}
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.url}
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.startDate}
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.endDate}
        </td>

        <td className="px-2 py-2 w-44 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-5 rounded-full text-center font-semibold w-max ${item.status === "Active"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
              }`}
          >
            {item.status}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <button
            className={`w-[52px] h-[24px]  rounded-full border-[1px]  flex items-center relative ${isOn
              ? "border-green-500 bg-green-600/20"
              : "border-[#F21531] bg-[#F21531]/20"
              }`}
            onClick={() => {
              setIsOn(!isOn);
            }}
          >
            <div
              className={`h-[17px] w-[17px] rounded-full  absolute animation ${isOn ? "left-7 bg-green-600" : "left-1 bg-[#F21531]"
                }`}
            />
          </button>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#0025F2] relative">
          <div
            className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
          >
            <DotMenuIcon />
          </div>
          <div
            className={`menuList-block flex items-center absolute !bg-transparent !border-none !shadow-none !top-[20px] !left-[-30px] ${hide ? "show" : ""
              }`}
          >
            <div className="menu-item flex items-center flex-col anim !gap-2">
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#9C8579] !p-0 !border-none"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <EditIcon3 />
              </div>
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#6DBCC3] !p-0 !border-none"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <EyeIcon2 />
              </div>
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#FF5A5D] !p-0"
                onClick={(e) => {
                  setHide(!hide);
                  setOpenDeleteModal(true);
                }}
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading && <AppLoader />}
      <Layout pageTitle={words["Banner"]}>
        <div className="banner-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                <SearchBar />
              </div> */}
                <div className="flex flex-1 justify-end">
                  <button
                    style={{ zIndex: 1 }}
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words["Add"]}
                    </label>
                  </button>
                </div>
              </div>
              {/* <div className="responsive-table overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      No
                    </th>
                    <th className="px-2 py-2 text-left w-28 text-sm text-black font-medium">
                      Image
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Title
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      URL
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Start Date
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      End Date
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Status
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Status
                    </th>
                    <th className="px-2 py-2 text-center text-sm text-black font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((item, index) => (
                    <TableRow item={item} index={index} />
                  ))}
                </tbody>
              </table>
            </div> */}

              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={banners}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <BannerModel
              onSuccessCreate={() => getBanners()}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              onSubmit={() => doDeleteBanner()}
              setOpenModal={setOpenDeleteModal}
            />
          </Modal>
          <Modal open={editModal} setOpen={setEditModal}>
            <EditBanner
              onSuccessCreate={() => getBanners()}
              data={editModalItem}
              openModal={editModal}
              setOpenModal={setEditModal}
            />
          </Modal>
        </div>
      </Layout>
    </>
  );

};

export default Banner;
