

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
// import { useGetBookingsQuery, useGetFinanceQuery, useMarkPaymentAsPaidMutation } from "../../store/services/bookingService";
import DataTable from "../../components/DataTable";
import { Dropdown, Menu, Button } from "antd";
import { getDateString } from "../../utils/functions";
import Modal from "../../components/Modal";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { useGetFinanceQuery } from "../../store/services/adsService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdSettingsModel from "../../components/AdSettingsModel";
import AppLoader from "../../components/Loader/AppLoader";

const Finance = () => {

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);
  const { data: financeList, isLoading: bookingLoading } = useGetFinanceQuery();
  const navigate = useNavigate();



  const columns = [
    {
      title: words["Transaction id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Ad Id"],
      dataIndex: "ad_id",
      key: "ad_id",
      render: (_, record) => (
        <div>
          <div
            onClick={() => navigate('/dashboard/ad-details/' + record.ad_id)}
            className="px-2 py-2 text-left text-sm text-black font-medium hover:cursor-pointer underline"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: 12
              // color:'blue'
            }}
          >
            {record.ad_id}
          </div>
        </div>
      )
    },
    {
      title: words["User name"],
      dataIndex: "name",
      key: "name",
    },
    {
      title: words["Phone Number"],
      dataIndex: "number",
      key: "number",
    },
    {
      title: words["Duration"],
      dataIndex: "ad_duration",
      key: "ad_duration",
      render: (_, record) => (
        <div>
          <div
            onClick={() => navigate('/dashboard/ad-details/' + record.ad_id)}
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: 12
              // color:'blue'
            }}
          >
            {words[record.ad_duration]}
          </div>
        </div>
      )
    },

    {
      title: words["Date"],
      // dataIndex: "start_date",
      // key: "start_date",
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: 12
            }}
          >
            {getDateString(record.start_date) + " - " + getDateString(record.end_date)}
          </div>
        </div>
      ),
    },

    {
      title: words["Total amount"],
      dataIndex: "order_amount",
      key: "order_amount",
    },
    {
      title: words["Transaction date"],
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: 12
            }}
          >
            {getDateString(record.created_at)}
          </div>
        </div>
      ),
    },

    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate('/dashboard/invoice', { state: { data: record } });

                }
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
                {/* <Menu.Item key="edit">Edit</Menu.Item> */}
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },


  ];

  // const doChangeStatus = () => {

  //   submitData(deletedId);

  // }

  // useEffect(() => {
  //   console.log('paymentStatusData', paymentStatusData);
  //   if (paymentStatusData?.status == 1) {
  //     toast.success(paymentStatusData.message ?? "Status updated Successfully");

  //     // setOpenModal(false)
  //     // navigate("/dashboard/stores");
  //   }
  //   if (paymentStatusData?.status == 0) {
  //     // console.log('deleteResponse.isError', deleteResponse.isError)
  //     toast.error("Something wrong try again later!");
  //   }
  // }, [paymentStatusData]);

  return (
    <>
      {
        loading || bookingLoading ? <AppLoader /> : null
      }
      <Layout pageTitle={words["Finance"]}>
        <div className="finance-page min-h-screen flex">


          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                <div className="flex flex-1 justify-end"></div>
              </div>

              <div className="flex flex-1 justify-end">
                <button
                  style={{ zIndex: 1, }}
                  className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                  onClick={(e) => setOpenModal(true)}
                >
                  <div

                    className="flex items-center justify-center text-white font-normal text-2xl">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5344 14.4181C13.127 14.4181 14.418 13.127 14.418 11.5345C14.418 9.94191 13.127 8.65088 11.5344 8.65088C9.94185 8.65088 8.65082 9.94191 8.65082 11.5345C8.65082 13.127 9.94185 14.4181 11.5344 14.4181Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M1.92242 12.3802V10.6885C1.92242 9.68883 2.73944 8.8622 3.7487 8.8622C5.48847 8.8622 6.19976 7.63187 5.32507 6.12278C4.82525 5.2577 5.12322 4.1331 5.99791 3.63328L7.66078 2.68169C8.42013 2.22993 9.40056 2.49906 9.85232 3.25841L9.95805 3.44104C10.8231 4.95012 12.2457 4.95012 13.1204 3.44104L13.2261 3.25841C13.6779 2.49906 14.6583 2.22993 15.4177 2.68169L17.0805 3.63328C17.9552 4.1331 18.2532 5.2577 17.7534 6.12278C16.8787 7.63187 17.59 8.8622 19.3297 8.8622C20.3294 8.8622 21.156 9.67922 21.156 10.6885V12.3802C21.156 13.3798 20.339 14.2065 19.3297 14.2065C17.59 14.2065 16.8787 15.4368 17.7534 16.9459C18.2532 17.8206 17.9552 18.9356 17.0805 19.4354L15.4177 20.387C14.6583 20.8387 13.6779 20.5696 13.2261 19.8103L13.1204 19.6276C12.2553 18.1186 10.8327 18.1186 9.95805 19.6276L9.85232 19.8103C9.40056 20.5696 8.42013 20.8387 7.66078 20.387L5.99791 19.4354C5.12322 18.9356 4.82525 17.811 5.32507 16.9459C6.19976 15.4368 5.48847 14.2065 3.7487 14.2065C2.73944 14.2065 1.92242 13.3798 1.92242 12.3802Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <divs style={{ fontSize: 13, marginLeft: lang == 'ar' ? 0 : 5, marginRight: lang == 'ar' ? 5 : 0 }}>
                      {words["Ad settings"]}
                    </divs>
                  </div>
                  <label className="text-white cursor-pointer">
                  </label>
                </button>
              </div>

              <div style={{ marginTop: -30 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={financeList?.data ?? []}
                  columns={columns}
                />
              </div>
            </div>
          </div>

        </div>

        <Modal open={openModal} setOpen={setOpenModal}>
          <AdSettingsModel
            setLoading={(v) => setLoading(v)}
            loading={loading}
            // onSuccessCreate={() => getUsers()}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Modal>
      </Layout>
    </>
  );
};

export default Finance;
