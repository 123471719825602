import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown, Menu, Button, Typography } from "antd";

import Layout from "../../components/Layout";
import Modal from "../../components/Modal";
import {
  DotMenuIcon,
  SettingIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import AdSettingsModel from "../../components/AdSettingsModel";
import { Link, useNavigate } from "react-router-dom";
import {
  useApproveAdMutation,
  useGetAdsQuery,
  useRejectAdMutation,
} from "../../store/services/adsService";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { IMAGE_URL } from "../../Api/constants";
import DataTable from "../../components/DataTable";
import AppLoader from "../../components/Loader/AppLoader";
import RejectedReasonModel from "../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { Image } from 'antd';


const Ads = () => {
  const [openModal, setOpenModal] = useState(false);
  const words = useSelector((state) => state.authReducer.words);
  const [rejectedAdId, setRejectedAdId] = useState("");
  const [rejectedAdModel, setRejectedAdModel] = useState(false);
  const navigate = useNavigate();

  const { data: ads, isLoading } = useGetAdsQuery();
  const [
    approveAd,
    {
      isLoading: adApproveLoading,
      isSuccess: approveAdSuccess,
      isError: approveError,
    },
  ] = useApproveAdMutation();
  const [
    rejectAd,
    {
      isLoading: adRejectLoading,
      isSuccess: rejectAdSuccess,
      isError: rejectError,
      response,
    },
  ] = useRejectAdMutation();

  const columns = [
    {
      title: words["id"],
      dataIndex: "id",
      key: "id",
    },
    // {
    //   title: words["Image"],
    //   dataIndex: "image",
    //   key: "image",
    //   render: (_, record) => (
    //     <div className="flex items-center">
    //       <Image
    //         style={{ width: 60, height: 60 }}
    //         src={IMAGE_URL + record.image}
    //         alt={record.image}
    //         className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
    //       />
    //     </div>
    //   ),
    // },
    {
      title: words["Title"],
      dataIndex: "title",
      key: "title",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            maxWidth: 100,
            overflow: 'hidden',
          }}
        >
          {record.title}
        </div>
      ),
    },
    // {
    //   title: words["Description"],
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: words["Category"],
      dataIndex: "ad_category",
      key: "ad_category",


    },
    {
      title: words["Duration"],
      dataIndex: "ad_duration",
      key: "ad_duration",
    },

    {
      title: words["Start date"] + " - " + words["End date"],
      dataIndex: "start_date",
      key: "start_date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.start_date)} - {getDateString(record.end_date)}
        </div>
      ),
    },

    // {
    //   title: words["End date"],
    //   dataIndex: "end_date",
    //   key: "end_date",
    //   render: (_, record) => (
    //     <div
    //       className="px-2 py-2 text-left text-sm text-black font-medium"
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       {getDateString(record.end_date)}
    //     </div>
    //   ),
    // },

    {
      title: words["Total amount"],
      dataIndex: "total_amount",
      key: "total_amount",
    },
    // {
    //   title: words["Tax"],
    //   dataIndex: "tax",
    //   key: "tax",
    // },
    // {
    //   title: words["Payment status"],
    //   dataIndex: "payment_status",
    //   key: "payment_status",
    //   render: (_, record) => (
    //     <div
    //       className="px-2 py-2 text-left text-sm text-black font-medium"
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       {capitalizeFirstLetter(record.payment_status)}
    //     </div>
    //   ),
    // },

    {
      title: words["Status"],
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {words[capitalizeFirstLetter(record.status)]}
        </div>
      ),
    },
    // {
    //   title: words["Rejected reason"],
    //   dataIndex: "rejected_reason",
    //   key: "rejected_reason",
    // },

    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate('/dashboard/ad-details/' + record.id);
                  // setEditModalItem(record)
                  // setEditModal(true);
                }
                if (key == "approve") {
                  doApproveAd(record.id);
                  // setEditModalItem(record)
                  // setEditModal(true);
                }
                if (key == "reject") {
                  setRejectedAdId(record.id);
                  setRejectedAdModel(true);
                  // doRejectAd(record.id)
                  // setDeletedId(record.id);
                  // setOpenDeleteModal(true);
                }
              }}
            >
              <>
                <Menu.Item key="view">{words["View Ad"]}</Menu.Item>
                <Menu.Item key="approve">{words["Approve"]}</Menu.Item>
                <Menu.Item key="reject">{words["Reject"]}</Menu.Item>
                {/* <Menu.Item key="edit">Edit</Menu.Item> */}
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const doApproveAd = (id) => {
    approveAd(id);
  };

  const doRejectAd = (reason) => {
    const body = {
      rejected_reason: reason,
    };

    rejectAd({
      data: body,
      id: rejectedAdId,
    });
    setRejectedAdModel(false);
  };

  useEffect(() => {
    if (approveAdSuccess) {
      toast.success("Ad Approved Succssfully");
    }
    if (approveError) {
      toast.error("Error while approving Ad. Please try again later");
    }
  }, [approveAdSuccess, approveError]);

  useEffect(() => {
    if (rejectAdSuccess) {
      toast.success("Ad Rejected Succssfully");
    }
    if (rejectError) {
      toast.error("Error while rejecting Ad. Please try again later");
    }
  }, [rejectAdSuccess, rejectError]);

  return (
    <>
      {isLoading || adApproveLoading || (adRejectLoading && <AppLoader />)}

      <Layout pageTitle={words["Ads"]}>
        <div className="finance-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                <SearchBar />
              </div> */}

                <div className="flex flex-1 justify-end">
                  <button
                    style={{ zIndex: 1 }}
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => navigate('/dashboard/new-ad')}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words["Add"]}
                    </label>
                  </button>
                </div>

                {/* <div className="flex flex-1 justify-end">
                <button
                  className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                  onClick={(e) => setOpenModal(true)}
                >
                  <div className="flex items-center justify-center text-white font-normal text-2xl h-5 w-5">
                    <SettingIcon />
                  </div>
                  <label className="text-white cursor-pointer">
                    Ad settings
                  </label>
                </button>
              </div> */}
              </div>
              {/* <div className="responsive-table overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      No
                    </th>

                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      ID
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Name
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Email
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Duration
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Section
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Start date
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      End date
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Amount/monthly
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Status
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {ads?.data?.map((item, index) => (
                    <TableRow item={item} index={index} />
                  ))}
                </tbody>
              </table>
            </div> */}
              <div>
                <DataTable

                  searchContainerStyle={{ width: "40%", marginTop: -40 }}
                  data={ads?.data ?? []}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <AdSettingsModel
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          <Modal open={rejectedAdModel} setOpen={setRejectedAdModel}>
            <RejectedReasonModel
              openModal={rejectedAdModel}
              setOpenModal={setRejectedAdModel}
              onSubmit={(reason) => doRejectAd(reason)}
            />
          </Modal>
        </div>
      </Layout>
    </>
  );
};

export default Ads;
