import React, { useState } from "react";
import Layout from "../../components/Layout";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import MenuDropDown from "../../components/MenuDropDown";
import { Link } from "react-router-dom";

const Booking = () => {
  const bookingData = [
    {
      user: "Ali ahamd",
      phone: "+968 96669730",
      email: "Ahmad29@gmail.com",
      idBooking: "#U3123",
      services: "Wedding day preparation",
      price: "100 OMR",
      fromDate: "12/1/2024",
      toDate: "12/1/2025",
      status: "Pending",
    },
    {
      user: "Ali ahamd",
      phone: "+968 96669730",
      email: "Ahmad29@gmail.com",
      idBooking: "#U342",
      services: "Wedding day preparation",
      price: "100 OMR",
      fromDate: "12/1/2024",
      toDate: "12/1/2025",
      status: "Progress",
    },
    {
      user: "Ali ahamd",
      phone: "+968 96669730",
      email: "Ahmad29@gmail.com",
      idBooking: "#U637",
      services: "Wedding day preparation",
      price: "100 OMR",
      fromDate: "12/1/2024",
      toDate: "12/1/2025",
      status: "Complete",
    },
  ];

  const menuList = [
    {
      item: (
        <Link
          to="/dashboard/booking/1"
          className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
        >
          View
        </Link>
      ),
    },
    {
      item: (
        <Link
          to="#"
          className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
        >
          Edit
        </Link>
      ),
    },
    {
      item: (
        <Link
          to="#"
          className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
        >
          Delete
        </Link>
      ),
    },
  ];

  return (
    <Layout>
      <div className="booking-page min-h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="flex flex-col gap-5 mt-6">
            <div className="flex items-center justify-between gap-3">
              <div className="flex flex-1">
                <SearchBar />
              </div>
              <div className="flex flex-1"></div>
            </div>
            <div className="responsive-table overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      No
                    </th>
                    <th className="px-2 py-2 text-left w-56 text-sm text-black font-medium">
                      User
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Phone
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Email
                    </th>
                    <th className="px-2 py-2 text-left w-40 text-sm text-black font-medium">
                      ID Booking
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Services
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Price
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      From Date
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      To Date
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Status
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {bookingData.map((item, index) => (
                    <tr
                      key={index}
                      className={`p-2 ${
                        index % 2 === 0 ? "bg-white" : "bg-white"
                      }`}
                    >
                      <td className="px-2 py-2 text-sm text-black font-medium">
                        {index + 1}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div className="flex items-center gap-2">
                          <img
                            src="/images/user1.png"
                            alt="userImage"
                            className="h-8 w-8 rounded-full object-contain bg-gray-300"
                          />
                          {item.user}
                        </div>
                      </td>
                      <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                        {item.phone}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.email}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.idBooking}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.services}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.price}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.fromDate}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.toDate}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div
                          className={`py-1 px-2 rounded-lg text-center font-semibold border ${
                            item.status === "Complete"
                              ? "bg-[#0DB214]/40 text-[#0DB214] border-[#0DB214]"
                              : item.status === "Progress"
                              ? "bg-[#4F52FF]/40 text-[#4F52FF] border-[#4F52FF]"
                              : "bg-[#FFC005]/40 text-[#FFC005] border-[#FFC005]"
                          }`}
                        >
                          {item.status}
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {/* Dropdown menu  */}
                        <MenuDropDown menuList={menuList} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Booking;
