import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import { DotMenuIcon, TrashIcon, EyeIcon2 } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import ApprovalRequests from "./ApprovalRequests";
import NewsTable from "./NewsTable";
import AdsTable from "./AdsTable";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import AppLoader from "../../components/Loader/AppLoader";
import { useSelector } from "react-redux";

const News = () => {
  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));

  const tabs = [
    {
      label: words["Approval requests"],
      value: "request",
    },
    {
      label: words["Posts"],
      value: "newsList",
    },
  ];

  const [approvePosts, setApprovalPosts] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPosts = async () => {
    const onSuccess = (res) => {
      setPosts(res.data);
      let toApprovePost = res.data?.filter((v, i) => v.status == "in-review");
      setApprovalPosts(toApprovePost);
      setLoading(false);
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest("", routes.getPosts, true, onSuccess, onError);
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      {loading && <AppLoader />}

      <Layout pageTitle={words["News"]}>
        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            {isAdmin?.type == "admin" ? (
              <div className="flex flex-col gap-5 mt-6">
                <div className="flex w-full border-b-[1px] border-b-themeColor border-transparent rounded ">
                  {tabs.map((item, index) => (
                    <button
                      key={index}
                      className={`btn font-normal rounded ${activeTab === item.value
                        ? " bg-themeColor text-white"
                        : "bg-transparent text-black"
                        }`}
                      onClick={(e) => setActiveTab(item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                {activeTab === "request" && (
                  <ApprovalRequests
                    refreshData={() => getPosts()}
                    data={approvePosts}
                  />
                )}
                {activeTab === "newsList" && (
                  <NewsTable
                    loadingState={(val) => {
                      setLoading(val)
                    }}
                    refreshData={() => getPosts()} data={posts} />
                )}
                {activeTab === "adsList" && <AdsTable />}
              </div>
            ) : (
              <NewsTable
                refreshData={() => getPosts()} data={posts} />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default News;
