import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.67 2.73L3.49 6.767c-.866.673-1.568 2.105-1.568 3.191v7.123c0 2.23 1.817 4.056 4.047 4.056h11.13a4.06 4.06 0 004.047-4.047v-6.997c0-1.163-.778-2.653-1.73-3.316l-5.94-4.162c-1.346-.942-3.508-.894-4.806.115zM11.534 17.292v-2.884"
      ></path>
    </svg>
  );
}

export default HomeIcon;
