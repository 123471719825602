import React, { useState, useEffect } from "react";
import { DotMenuIcon, TrashIcon, EyeIcon2 } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import { Dropdown, Menu, Button, Typography } from "antd";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import AppLoader from "../../components/Loader/AppLoader";
import DataTable from "../../components/DataTable";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import AddPostModel from "../../components/AddPostModal";
import { useSelector } from "react-redux";
import { Image } from 'antd';
import { useNavigate } from "react-router-dom";
import RejectedReasonModel from "../../components/RejectedReasonModel";

const ApprovalRequests = ({ data = [], refreshData = () => { } }) => {

  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const [rejectedAd, setRejectedAd] = useState();
  const [rejectedAdModel, setRejectedAdModel] = useState(false);

  const columns = [
    {
      title: words["id"],
      dataIndex: "id",
      key: "id",
    },
    // {
    //   title: words["Images"],
    //   dataIndex: "image",
    //   key: "image",
    //   render: (_, record) => (
    //     <div className="flex items-center">
    //       <Image
    //         style={{ width: 60, height: 60 }}
    //         src={IMAGE_URL + record.image}
    //         alt={record.image}
    //         className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
    //       />
    //     </div>
    //   ),
    // },
    {
      title: words["Title"],
      dataIndex: "title",
      key: "title",
    },

    // {
    //   title: words["Description"],
    //   dataIndex: "description",
    //   key: "description",
    //   render: (_, record) => (
    //     <div
    //       className="px-2 py-2 text-left text-sm text-black font-medium"
    //       style={{
    //         maxWidth: 150,
    //         overflow: 'hidden'
    //         // display: "flex",
    //         // flexDirection: "row",
    //         // alignItems: "center",
    //       }}
    //     >
    //       {record.description}
    //     </div>
    //   ),
    // },
    {
      title: words["Category"],
      dataIndex: "category",
      key: "category",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {words[record.category]}
        </div>
      ),
    },
    {
      title: words["Status"],
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <div
          className={`py-2 px-0 rounded-full text-center font-semibold ${record.status?.toLowerCase() === "published"
            ? "bg-[#0DB214]/40 text-[#0DB214]"
            : "bg-red-200 text-red-400"
            }`}
        >
          {words[capitalizeFirstLetter(record.status)]}
        </div>
      ),
    },
    {
      title: words["Created at"],
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == 'view') {
                  navigate('/dashboard/post-details/' + record.id);
                  return;
                }

                if (key == 'rejected') {
                  setRejectedAd(record);
                  setRejectedAdModel(true);
                  return;
                }
                doChangePostStatus(key, record);
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
                <Menu.Item key="rejected">{words["Reject"]}</Menu.Item>
                <Menu.Item key="published">{words["Published"]}</Menu.Item>
                <Menu.Item key="in-review">{words["In-Review"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const doChangePostStatus = async (status, record, reason = null) => {
    setRejectedAdModel(false);
    const onSuccess = (res) => {
      setLoading(false);
      refreshData();

      // getDashboard();
    };

    const onError = (err) => {
      setLoading(false);
      setLoading(false);
      toast.error(err?.error);
    };

    const body = {
      status: status,
      id: record.id,
    };
    if (status == "rejected") body.rejected_reason = reason;
    setLoading(true);
    await postRequest(body, routes.updatePostStatus, true, onSuccess, onError);
  };

  const ApprovalRequestTableRow = ({ item, index }) => {
    const [hide, setHide] = useState(false);
    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);
    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-2 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center">
            <img
              src="/images/post-img.png"
              alt="userImage"
              className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
            />
          </div>
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.title}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`${item.type === "Ad" ? "text-[#F49A40]" : "text-[#0DB214]"
              }`}
          >
            {item.type}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.date}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.category}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center gap-2">
            <img
              src="/images/user1.png"
              alt="userImage"
              className="h-8 w-8 rounded-full object-contain bg-gray-300"
            />
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-2 rounded-full text-center font-semibold ${item.status === "Publishing"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : item.status === "Review"
                ? "bg-[#FFDF9F] text-[#FFAA01]"
                : "bg-red-200 text-red-400"
              }`}
          >
            {item.status}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#0025F2] relative">
          <div
            className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
          >
            <DotMenuIcon />
          </div>
          <div
            className={`menuList-block flex items-center absolute !bg-transparent !border-none !shadow-none !top-[20px] !left-[-30px] ${hide ? "show" : ""
              }`}
          >
            <div className="menu-item flex items-center flex-col anim !gap-2">
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#6DBCC3] !p-0 !border-none"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <EyeIcon2 />
              </div>
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#FF5A5D] !p-0"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div className="flex flex-col w-full gap-4">
      {loading && <Loader />}
      {/* <div className="flex items-center justify-between gap-3">
        <div className="flex flex-1 justify-end">
          <div className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1">
            <div className="flex items-center justify-center text-white font-normal text-2xl">
              +
            </div>
            <label className="text-white cursor-pointer">Add</label>
          </div>
        </div>
      </div> */}

      <div className="flex flex-1 justify-end">
        <div
          style={{ zIndex: 1 }}
          onClick={() => setOpenModal(true)}
          className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
        >
          <div className="flex items-center justify-center text-white font-normal text-2xl">
            +
          </div>
          <label className="text-white cursor-pointer">{words["Add"]}</label>
        </div>
      </div>

      {/* <div className="responsive-table overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-[#F5F7FB]">
            <tr>
              <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                No
              </th>
              <th className="px-2 py-2 text-left  text-sm text-black font-medium">
                Image
              </th>
              <th className="px-2 py-2 text-left w-56 text-sm text-black font-medium">
                Title
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                Type
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                Date
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                Category
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                ID
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                Publisher
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                Status
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {storeData.map((item, index) => (
              <ApprovalRequestTableRow item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div> */}
      <div style={{ marginTop: -50 }}>
        <DataTable
          searchContainerStyle={{ width: "40%" }}
          data={data}
          columns={columns}
        />
      </div>

      <Modal open={openModal} setOpen={setOpenModal}>
        <AddPostModel
          onSuccessCreate={() => refreshData()}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </Modal>

      <Modal open={rejectedAdModel} setOpen={setRejectedAdModel}>
        <RejectedReasonModel
          openModal={rejectedAdModel}
          setOpenModal={setRejectedAdModel}
          onSubmit={(reason) =>
            doChangePostStatus("rejected", rejectedAd, reason)
          }
        />
      </Modal>


    </div>
  );
};

export default ApprovalRequests;
