import React from "react";

function BellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M11.554 2.797a5.772 5.772 0 00-5.768 5.767v2.778c0 .586-.25 1.48-.547 1.98l-1.106 1.836c-.682 1.134-.211 2.394 1.038 2.816a20.11 20.11 0 0012.755 0 1.925 1.925 0 001.038-2.816l-1.105-1.836c-.288-.5-.538-1.393-.538-1.98V8.564c0-3.172-2.595-5.767-5.767-5.767z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M13.332 3.076a6.492 6.492 0 00-3.556 0 1.908 1.908 0 011.778-1.211c.807 0 1.5.5 1.778 1.21z"
      ></path>
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M14.437 18.32a2.892 2.892 0 01-2.883 2.884 2.894 2.894 0 01-2.038-.846 2.894 2.894 0 01-.846-2.037"
      ></path>
    </svg>
  );
}

export default BellIcon;
