import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const adsServices = createApi({
  reducerPath: "ads",
  tagTypes: "ad",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      // const token = reducers?.authReducer?.token;
      const token = localStorage.getItem('admin-token')
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {

      getAds: builder.query({
        query: () => {
          return {
            url: "/api/admin/ads",
            method: "GET",
          };
        },
        providesTags: ["ad"],
      }),

      approveAd: builder.mutation({
        query: (id) => {
          return {
            url: "/api/admin/ads/approve/" + id,
            method: "PUT",
            // body: data.data,
          };
        },
        invalidatesTags: ["ad"],
      }),

      rejectAd: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/ads/reject/" + data.id,
            method: "PUT",
            body: data.data,
          };
        },
        invalidatesTags: ["ad"],
      }),

      getFinance: builder.query({
        query: () => {
          return {
            url: "/api/admin/ads/finance",
            method: "GET",
          };
        },
        invalidatesTags: ["ad"],
      }),
    };
  },
});
export const { useApproveAdMutation, useRejectAdMutation, useGetAdsQuery, useGetFinanceQuery } = adsServices;
export default adsServices;
