import React from "react";

function AttachmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.33 12.15l-2.47 2.47a3.495 3.495 0 000 4.95 3.495 3.495 0 004.95 0l3.89-3.89a7.007 7.007 0 000-9.9 7.007 7.007 0 00-9.9 0l-4.24 4.24c-2.34 2.34-2.34 6.14 0 8.49"
      ></path>
    </svg>
  );
}

export default AttachmentIcon;
