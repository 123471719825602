import React, { useState } from "react";
import Layout from "../../components/Layout";
import { DotMenuIcon, ReorderIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import FeaturedModel from "../../components/FeaturedModel";

const Featured = () => {
  const [openModal, setOpenModal] = useState(false);
  const data = [
    {
      title: "Example title notification!",
      desc: "Stores",
      rank: 1,
      status: "Active",
    },
    {
      title: "Example title notification!",
      desc: "Products",
      rank: 2,
      status: "Active",
    },
    {
      title: "Example title notification!",
      desc: "Default",
      rank: 3,
      status: "Active",
    },
    {
      title: "Example title notification!",
      desc: "Default",
      rank: 4,
      status: "Inactive",
    },
  ];
  return (
    <Layout pageTitle="Featured Section">
      <div className="notification-page min-h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="flex flex-col gap-5 mt-6">
            <div className="flex items-center justify-between gap-3">
              <div className="flex flex-1"></div>
              <div className="flex flex-1 justify-end">
                <button
                  className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                  onClick={(e) => setOpenModal(true)}
                >
                  <div className="flex items-center justify-center text-white font-normal text-2xl">
                    +
                  </div>
                  <label className="text-white cursor-pointer">Add</label>
                </button>
              </div>
            </div>
            <div className="responsive-table overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      No
                    </th>

                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Title
                    </th>
                    <th className="px-2 py-2 text-left w-52 break-words text-sm text-black font-medium">
                      Section Type
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Status
                    </th>
                    <th className="px-2 py-2 text-center text-sm text-black font-medium">
                      Reorder
                    </th>
                    <th className="px-2 py-2 text-center text-sm text-black font-medium">
                      Rank
                    </th>

                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className={`p-2 ${
                        index % 2 === 0 ? "bg-white" : "bg-white"
                      }`}
                    >
                      <td className="px-2 py-2 text-sm text-black font-medium">
                        {index + 1}
                      </td>
                      <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                        {item.title}
                      </td>
                      <td className="px-2 py-2 w-52 text-xs text-[#292D32]">
                        <div className="flex gap-3 break-words w-52">
                          {item.desc}
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div
                          className={`py-2 px-2 rounded-full text-center font-semibold ${
                            item.status === "Active"
                              ? "bg-[#0DB214]/40 text-[#0DB214]"
                              : "bg-red-200 text-red-400"
                          }`}
                        >
                          {item.status}
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div className="flex items-center justify-center">
                          <ReorderIcon />
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-center text-[#292D32]">
                        {item.rank}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div className="flex items-center justify-center cursor-pointer">
                          <DotMenuIcon />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal open={openModal} setOpen={setOpenModal}>
          <FeaturedModel openModal={openModal} setOpenModal={setOpenModal} />
        </Modal>
      </div>
    </Layout>
  );
};

export default Featured;
