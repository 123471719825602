import React, { useState } from "react";
import { CrossIcon } from "../assets/Icons";
import { ToggleButton } from "../components";

const FeaturedModel = ({ openModal, setOpenModal }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex flex-col gap-3 items-end mb-4">
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
        <div className="flex items-center justify-between gap-3 w-full">
          <h1 className="text-black text-xl font-medium">
            Add Featured Section
          </h1>
          <div className="flex items-center gap-1.5">
            <div className="text-base">Active</div>
            <ToggleButton checked={isActive} setChecked={setIsActive} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">Title</label>
          <input
            type="text"
            placeholder="Enter Title"
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">
            Section Types
          </label>
          <input
            type="text"
            placeholder="Enter Section Type"
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>

        <div className="flex items-center justify-center">
          <button className="button rounded-full !py-2 !px-10">
            Add Featured Section
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedModel;
