export const routes = {
    login: 'admin/login',
    dashboard: 'admin/dashboard',
    updatePostStatus: 'admin/update_post_status',
    getUsers: 'admin/get_users',
    getUser: 'admin/get_user',
    editUser: 'admin/edit_user',
    addUser: 'admin/add_user',
    deleteUser: 'admin/delete_user',
    getPosts: 'admin/get_posts',
    addPost: 'admin/create_post',
    getBanners: 'admin/get_banners',
    addBanner: 'admin/add_banner',
    editBanner: 'admin/edit_banner/',
    deleteBanner: 'admin/delete_banner',
    getSettings: 'admin/settings',
    addSettings: 'admin/addSettings',
    getProfile: 'admin/profile',
    updateProfile: 'admin/update_profile',
    getAdsDuration: 'admin/ads/getAdDurations',
    updateAdDuration: 'admin/ads/updateAdDuration',

}