import React from "react";

function UsersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.805 10.448a1.75 1.75 0 00-.318 0A4.249 4.249 0 014.383 6.19a4.264 4.264 0 014.268-4.268 4.263 4.263 0 01.154 8.526zM15.773 3.845a3.362 3.362 0 013.365 3.364 3.369 3.369 0 01-3.24 3.364 1.087 1.087 0 00-.25 0M3.998 13.995c-2.326 1.557-2.326 4.095 0 5.642 2.644 1.769 6.979 1.769 9.622 0 2.326-1.557 2.326-4.094 0-5.642-2.634-1.759-6.969-1.759-9.622 0zM17.628 19.224c.692-.144 1.346-.423 1.884-.836 1.5-1.125 1.5-2.98 0-4.105-.528-.403-1.172-.672-1.855-.826"
      ></path>
    </svg>
  );
}

export default UsersIcon;
