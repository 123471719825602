const ToggleButton = ({ checked, setChecked }) => {
  return (
    <button
      className={`w-[52px] ${checked && "border-themeColor"} h-[24px] bg-themeColor/30 rounded-full border-[1px] flex items-center relative`}
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <div
        className={`h-[17px] w-[17px] rounded-full bg-themeColor absolute animation ${checked ? "left-7" : "left-1"
          }`}
      />
    </button>
  );
};

export default ToggleButton;
