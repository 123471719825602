import React from "react";

function BannerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M19.224 14.86H3.844A3.844 3.844 0 010 11.016V4.287A3.844 3.844 0 013.845.442h15.379a3.844 3.844 0 013.845 3.845v6.729a3.844 3.844 0 01-3.845 3.844zM3.844 2.365a1.928 1.928 0 00-1.922 1.922v6.729c0 1.057.865 1.922 1.923 1.922h15.379a1.928 1.928 0 001.922-1.922V4.287a1.928 1.928 0 00-1.922-1.922H3.844zm-.48 21.146h-.961A2.404 2.404 0 010 21.108v-.961a2.404 2.404 0 012.403-2.403h.961a2.404 2.404 0 012.403 2.403v.961a2.404 2.404 0 01-2.403 2.403zm-.961-3.845c-.27 0-.48.212-.48.481v.961c0 .27.21.48.48.48h.961c.27 0 .48-.21.48-.48v-.961c0-.27-.21-.48-.48-.48h-.961zm18.263 3.845h-.961a2.404 2.404 0 01-2.403-2.403v-.961a2.404 2.404 0 012.403-2.403h.96a2.404 2.404 0 012.404 2.403v.961a2.404 2.404 0 01-2.403 2.403zm-.961-3.845c-.27 0-.481.212-.481.481v.961c0 .27.212.48.48.48h.962c.269 0 .48-.21.48-.48v-.961c0-.27-.211-.48-.48-.48h-.961zm-7.69 3.845h-.961a2.404 2.404 0 01-2.403-2.403v-.961a2.404 2.404 0 012.403-2.403h.961a2.404 2.404 0 012.403 2.403v.961a2.404 2.404 0 01-2.403 2.403zm-.961-3.845c-.27 0-.48.212-.48.481v.961c0 .27.21.48.48.48h.961c.27 0 .48-.21.48-.48v-.961c0-.27-.21-.48-.48-.48h-.961z"
      ></path>
    </svg>
  );
}

export default BannerIcon;
