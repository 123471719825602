import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Public = ({ children }) => {
  const { adminToken } = useSelector((state) => state.authReducer);
  // const isAdmin = JSON.parse(localStorage.getItem('admin'));
  const adminDataString = localStorage.getItem("admin");

  let isAdmin = null;
  if (adminDataString) {
    try {
      // Attempt to parse the stored string as JSON
      isAdmin = JSON.parse(adminDataString);
    } catch (error) {
      // Handle parsing error (if the stored value is not valid JSON)
      console.error('Error parsing admin data:', error);
    }
  }




  return adminToken && isAdmin ? <Navigate to="/dashboard/home" /> : children;
};

export default Public;
