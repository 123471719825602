import React from "react";
import Layout from "../../components/Layout";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import { useSelector } from "react-redux";

const Subscriptions = () => {
  const words = useSelector((state) => state.authReducer.words);
  const usersData = [
    {
      store: "Store Name",
      phone: "+968 96669730",
      interval: "Per 12 month",
      price: "100 OMR",
      startDate: "12/1/2024",
      endDate: "12/1/2025",
      status: "Active",
    },
    {
      store: "Store Name",
      phone: "+968 96669730",
      interval: "Per 12 month",
      price: "100 OMR",
      startDate: "12/1/2024",
      endDate: "12/1/2025",
      status: "Active",
    },
    {
      store: "Store Name",
      phone: "+968 96669730",
      interval: "Per 12 month",
      price: "100 OMR",
      startDate: "12/1/2024",
      endDate: "12/1/2025",
      status: "Active",
    },
    {
      store: "Store Name",
      phone: "+968 96669730",
      interval: "Per 12 month",
      price: "100 OMR",
      startDate: "12/1/2024",
      endDate: "12/1/2025",
      status: "Inactive",
    },
  ];
  return (
    <Layout>
      <div className="store-page min-h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="flex flex-col gap-5 mt-6">
            <div className="flex items-center justify-between gap-3">
              <div className="flex flex-1">
                <SearchBar />
              </div>
              <div className="flex flex-1 justify-end">
                <button className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]">
                  <div className="flex items-center justify-center text-white font-normal text-2xl">
                    +
                  </div>
                  <label className="text-white cursor-pointer">Add</label>
                </button>
              </div>
            </div>
            <div className="responsive-table overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      {words["No"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["Stores"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["Phone"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["Interval"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["Price"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["Stat Date"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["End Date"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["Status"]}
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      {words["Action"]}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {usersData.map((item, index) => (
                    <tr
                      key={index}
                      className={`p-2 ${
                        index % 2 === 0 ? "bg-white" : "bg-white"
                      }`}
                    >
                      <td className="px-2 py-2 text-sm text-black font-medium">
                        {index + 1}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.store}
                      </td>
                      <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                        {item.phone}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.interval}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.price}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.startDate}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.endDate}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div
                          className={`py-2 px-2 rounded-full text-center font-semibold ${
                            item.status === "Active"
                              ? "bg-[#0DB214]/40 text-[#0DB214]"
                              : "bg-red-200 text-red-400"
                          }`}
                        >
                          {item.status}
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div className="flex items-center justify-center cursor-pointer">
                          <DotMenuIcon />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Subscriptions;
