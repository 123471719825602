import React from "react";

function ChartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        stroke="#A0009A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        d="M29.488 32.52h-7.73a3.323 3.323 0 00-3.325 3.326v14.933h11.055V32.521v0z"
      ></path>
      <path
        stroke="#A0009A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        d="M37.22 19.25h-4.433a3.324 3.324 0 00-3.325 3.325V50.75h11.054V22.575c0-1.837-1.458-3.325-3.296-3.325zM48.265 37.48h-7.729v13.27H51.59v-9.946c-.029-1.837-1.516-3.325-3.325-3.325z"
      ></path>
      <path
        stroke="#A0009A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M26.25 64.167h17.5c14.583 0 20.417-5.834 20.417-20.417v-17.5c0-14.583-5.834-20.417-20.417-20.417h-17.5c-14.583 0-20.417 5.834-20.417 20.417v17.5c0 14.583 5.834 20.417 20.417 20.417z"
      ></path>
    </svg>
  );
}

export default ChartIcon;
