import React from "react";

function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.25 3.488a59.326 59.326 0 00-5.845-.291c-1.155 0-2.31.058-3.465.175l-1.19.116M4.958 2.9l.129-.765c.093-.554.163-.968 1.149-.968h1.528c.986 0 1.062.437 1.15.974l.128.758M10.996 5.332l-.38 5.874c-.063.916-.116 1.627-1.744 1.627H5.127c-1.627 0-1.68-.711-1.744-1.627l-.379-5.874M6.026 9.625h1.942M5.542 7.292h2.916"
      ></path>
    </svg>
  );
}

export default TrashIcon;
