import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      fill="none"
      viewBox="0 0 26 25"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 24c3.114-3.352 7.343-5.412 12-5.412s8.886 2.06 12 5.412M19 7.088c0 3.363-2.686 6.089-6 6.089S7 10.45 7 7.088C7 3.726 9.686 1 13 1s6 2.726 6 6.088z"
      ></path>
    </svg>
  );
}

export default UserIcon;
