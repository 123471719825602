import React from "react";

function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M11.534 14.894a2.884 2.884 0 100-5.767 2.884 2.884 0 000 5.767z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M1.922 12.856v-1.691c0-1 .817-1.827 1.827-1.827 1.74 0 2.45-1.23 1.576-2.74-.5-.864-.202-1.989.673-2.489l1.663-.951c.76-.452 1.74-.183 2.191.576l.106.183c.865 1.51 2.288 1.51 3.162 0l.106-.183c.452-.759 1.432-1.028 2.192-.576l1.663.951c.874.5 1.172 1.625.672 2.49-.874 1.509-.163 2.74 1.577 2.74 1 0 1.826.816 1.826 1.826v1.691c0 1-.817 1.826-1.826 1.826-1.74 0-2.451 1.23-1.577 2.74.5.875.202 1.99-.672 2.49l-1.663.951c-.76.452-1.74.183-2.192-.577l-.106-.182c-.865-1.51-2.287-1.51-3.162 0l-.106.182c-.452.76-1.432 1.029-2.191.577l-1.663-.951a1.825 1.825 0 01-.673-2.49c.875-1.51.163-2.74-1.576-2.74a1.832 1.832 0 01-1.827-1.826z"
      ></path>
    </svg>
  );
}

export default SettingIcon;
