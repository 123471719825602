import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import AddUserModel from "../../components/AddUserModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { Link, useNavigate } from "react-router-dom";
import { getDateString } from "../../utils/functions";
import { Dropdown, Menu, Button } from "antd";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";

const Users = () => {
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersTemp, setUserTemp] = useState([]);
  const [deletedId, setDeletedId] = useState("");

  const columns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Name"],
      dataIndex: "name",
      key: "name",
    },
    {
      title: words["Phone"],
      dataIndex: "number",
      key: "number",
    },
    {
      title: words["Type"],
      dataIndex: "type",
      key: "type",
    },
    {
      title: words["Publishing Add"],
      dataIndex: "ads_count",
      key: "ads_count",
    },
    {
      title: words["Publishing Posts"],
      dataIndex: "posts_count",
      key: "posts_count",
    },
    {
      title: words["Publishing Posts"],
      dataIndex: "posts_count",
      key: "posts_count",
      render: (_, record) => (
        <div
          className={`py-2 px-2 rounded-full text-center font-semibold ${record.status == 1
            ? "bg-[#0DB214]/40 text-[#0DB214]"
            : "bg-red-200 text-red-400"
            }`}
        >
          {record.status == "1" ? words["Active"] : words["Inactive"]}
        </div>
      )
    },
    {
      title: words["Created at"],
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {getDateString(record.created_at)}
          </div>
        </div>
      ),
    },


    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "edit") {
                  navigate("/dashboard/edit-user/" + record.id);
                } else if (key == "delete") {
                  setDeletedId(record.id);
                  setOpenDeleteModal(true);
                }
                // handleActionClick(key, record)
              }}
            >
              <>
                <Menu.Item key="edit">{words["Edit"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const getUsers = async () => {
    const onSuccess = (res) => {
      setLoading(false);
      setUsers(res.data);
      setUserTemp(res.data);
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest("", routes.getUsers, true, onSuccess, onError);
  };

  const doDeleteUser = async () => {
    const onSuccess = (res) => {
      setDeletedId("");
      setLoading(false);
      getUsers();
    };

    const onError = (err) => {
      typeof err == 'string' && toast.error(err);
      setDeletedId("");
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest(
      { id: deletedId },
      routes.deleteUser,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  const UserTableRow = ({ item, index }) => {
    const [isOn, setIsOn] = useState(false);
    const [hide, setHide] = useState(false);

    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);

    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-3 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.name}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.number}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.type}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.posts_count}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.adds}
        </td>
        {/* <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-2 rounded-full text-center font-semibold ${item.status === "Active"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
              }`}
          >
            {item.status}
          </div>
        </td> */}
        {/* <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <button
            className={`w-[52px] h-[24px]  rounded-full border-[1px]  flex items-center relative ${isOn
              ? "border-green-500 bg-green-600/20"
              : "border-[#F21531] bg-[#F21531]/20"
              }`}
            onClick={() => {
              setIsOn(!isOn);
            }}
          >
            <div
              className={`h-[17px] w-[17px] rounded-full  absolute animation ${isOn ? "left-7 bg-green-600" : "left-1 bg-[#F21531]"
                }`}
            />
          </button>
        </td> */}
        <td className="px-3 py-2 whitespace-nowrap text-xs text-[#0025F2] relative">
          <div
            style={{ marginLeft: -40 }}
            className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
          >
            <DotMenuIcon />
          </div>
          <div
            // style={{zIndex:999999}}
            // style={{position:'absolute'}}
            className={`menuList-block flex items-center absolute !bg-transparent !border-none !shadow-none !top-[20px] !left-[-30px] ${hide ? "show" : ""
              }`}
          >
            <div className="menu-item flex items-center flex-col anim !gap-2">
              <Link
                to={`/dashboard/edit-user/${item.id}`}
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#9C8579] !p-0 !border-none"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <EditIcon3 />
              </Link>
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#6DBCC3] !p-0 !border-none"
                onClick={(e) => {
                  setHide(!hide);
                }}
              >
                <EyeIcon2 />
              </div>
              <div
                className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#FF5A5D] !p-0"
                onClick={(e) => {
                  setHide(!hide);
                  setOpenDeleteModal(true);
                }}
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Layout pageTitle={words["Users"]}>
      {loading ? (
        <Loader />
      ) : (
        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                <div className="flex flex-1 justify-end">
                  <button
                    style={{ zIndex: 1 }}
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words["Add"]}
                    </label>
                  </button>
                </div>
              </div>
              {/* <div className="responsive-table overflow-x-auto" style={{ minHeight: 170 }} >
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      No
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      ID
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Name
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Phone
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Type
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Publishing Post
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Publishing Add
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users?.map((item, index) => (
                    <UserTableRow item={item} index={index} />
                  ))}
                </tbody>
              </table>
            </div> */}

              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={users}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <AddUserModel
              onSuccessCreate={() => getUsers()}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
              onSubmit={() => {
                doDeleteUser();
              }}
            />
          </Modal>
        </div>
      )}
    </Layout>
  );
};

export default Users;
