import React from "react";
import { CrossIcon } from "../assets/Icons";
import { useSelector } from "react-redux";

const DeleteConfirmationModel = ({ openModal, setOpenModal, onSubmit = () => { } }) => {

  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);

  return (
    <div
      dir={lang == 'ar' ? 'rtl' : 'ltr'}
      className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex items-center justify-center gap-3 border-b border-[#D8D8D8] pb-3">
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
        <h1 className="text-black text-base font-medium w-full text-center">
          {words["Delete"]}
        </h1>
      </div>

      <div className="flex flex-col gap-5">
        <h1 className="text-lg font-medium text-black my-6 text-center">
          {words["Are you sure you want to delete the record ?"]}
        </h1>

        <div className="flex items-center justify-end gap-3">
          <button
            className="button rounded-lg !py-2 !px-6 !bg-[#3EBC9C] border-[#3EBC9C]"
            onClick={(e) => setOpenModal(false)}
          >
            {words["Cancel"]}
          </button>
          <button
            onClick={() => {
              setOpenModal(false);
              onSubmit();
            }}
            className="button rounded-lg !py-2 !px-6 !bg-[#FF4052] border-[#FF4052]">
            {words["Yes"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModel;
