import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const staffServices = createApi({
  reducerPath: "staff",
  tagTypes: "staff",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = localStorage.getItem('admin-token')
      // reducers?.authReducer?.token;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {

    return {
      getStaff: builder.query({
        query: () => {
          return {
            url: "/api/admin/staff",
            method: "GET",
          };
        },
        providesTags: ["staff"],
      }),

      addStaff: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/staff",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["staff"],
      }),

      deleteStaff: builder.mutation({
        query: (id) => {
          return {
            url: "/api/admin/staff/" + id,
            method: "DELETE"
          };
        },
        invalidatesTags: ["staff"],
      }),


    };
  },
});
export const { useAddStaffMutation, useDeleteStaffMutation, useGetStaffQuery } = staffServices;
export default staffServices;
