import React from "react";

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      fill="none"
      viewBox="0 0 30 24"
    >
      <path
        fillRule="evenodd"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.8 1h22.4C27.74 1 29 2.237 29 3.75v16.5c0 1.512-1.26 2.75-2.8 2.75H3.8C2.26 23 1 21.762 1 20.25V3.75C1 2.237 2.26 1 3.8 1z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M29 3.75l-14 9.625L1 3.75"
      ></path>
    </svg>
  );
}

export default MailIcon;
