import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Input, Layout, ToggleButton, UploadButton } from "../../components";
import { postRequestFormData } from "../../Api";
import { useSelector } from "react-redux";
import { AttachmentIcon } from "../../assets/Icons";


const categories = [
    "Food",
    "Hotels",
    "Discounts",
    "Properties",
];

const durations = [
    "1 Month",
    "3 Months",
    "6 Months",
    "1 Year"
];

const NewAd = () => {

    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    

    const [fileType, setFileType] = useState('');
    const [file, setFile] = useState(null);



    const [formData, setFormData] = useState({
        title: '',
        description: '',
        ad_category: '',
        ad_duration: '',
    });



    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };






  


    const handleSubmitSetting = async (e) => {
        e.preventDefault();
        // const data = new FormData();


        if (!formData.ad_category) return toast.error("Please select ad category");
        if (!formData.ad_duration) return toast.error("Please select ad duration");
        if(!file) return toast.error("Please select ad image or video");



        const onSuccess = (res) => {
            setLoading(false);
            toast.success("Ad post successfully");
            navigate("/dashboard/ads");
        }

        const onError = (err) => {
            toast.error(typeof err == 'string' ? err : "Something went wrong. Please try agian later!");
            setLoading(false);
            console.log('err', err);
        }
        setLoading(true)
        const body = {
            ...formData,
            file: file,
            file_type: fileType
        }
        await postRequestFormData(body, 'admin/post_ad', true, onSuccess, onError);


        // saveStore(data);
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const type = selectedFile.type.startsWith('image/') ? 'image' : 'video';
            setFileType(type);
            setFile(selectedFile);
        }
    };

    const handleClick = () => {
        document.getElementById('upload_file').click();
    };


    return (
        <Layout pageTitle={words["Create store"]}>
            <div className="w-full min-h-screen flex pb-10">
                <div className="wrap wrapWidth flex flex-col gap-6">
                    <form onSubmit={handleSubmitSetting} className="w-full gap-6">
                        <div className="w-full flex gap-6 mb-6">
                            <div className="w-full grid-box px-5 pt-4 pb-12 border-solid border-[1px] border-[#D8D8D8] rounded-[20px] flex flex-col">
                                <div className="w-full flex items-center mb-6">
                                    <div className="text-base font-bold flex-1">
                                        {words["Ad information"]}
                                    </div>
                                    {/* <div className="flex items-center gap-1.5">
                    <div className="text-base">{words["Active"]}</div>
                    <ToggleButton checked={isActive} setChecked={setIsActive} />
                  </div> */}
                                </div>
                                <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                    <div className="text-base text-black">{words["Title"]}</div>
                                    <Input
                                        placeholder={words["Title"]}
                                        name="title"
                                        value={formData.title}
                                        onChange={handleInput}
                                        required
                                    />
                                </div>


                                <div className="flex flex-col gap-2">
                                    <div className="text-base">{words["Description"]}</div>
                                    <textarea
                                        className="input h-[110px]"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInput}
                                    />
                                </div>


                                <div className="flex flex-col gap-2 mt-5">
                                    <label className="text-black text-sm font-normal">
                                        {words["Category"]}
                                    </label>
                                    <select
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            ad_category: e.target.value
                                        })}
                                        type="text"
                                        placeholder="Enter category"
                                        className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
                                    >
                                        <option disabled selected value="">{words["Select category"]}</option>
                                        {categories.map((d, i) => (
                                            <option key={i} value={d}>
                                                {words[d]}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="flex flex-col gap-2 mt-5">
                                    <label className="text-black text-sm font-normal">
                                        {words["Duration"]}
                                    </label>
                                    <select
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            ad_duration: e.target.value
                                        })}
                                        type="text"
                                        placeholder="Enter category"
                                        className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
                                    >
                                        <option disabled selected value="">{words["Select category"]}</option>
                                        {durations.map((d, i) => (
                                            <option key={i} value={d}>
                                                {words[d]}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                            </div>

                            <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
                                <div className="w-full flex items-center mb-6">
                                    <div className="text-base font-bold flex-1">{words["Ad video or image"]}</div>
                                </div>




                                <div className="flex items-center w-full">
                                    <div className="field flex flex-col w-full">
                                        <div
                                            className="img-box flex items-center justify-center h-40 w-full bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                                            onClick={handleClick}
                                        >
                                            {file ? (
                                                fileType === 'image' ? (
                                                    <img
                                                        alt="file"
                                                        src={URL.createObjectURL(file)}
                                                        className="h-full w-full overflow-hidden object-contain"
                                                    />
                                                ) : (
                                                    <video
                                                        controls
                                                        className="h-full w-full object-contain"
                                                    >
                                                        <source src={URL.createObjectURL(file)} type={file.type} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )
                                            ) : (
                                                <div className="flex items-center justify-between gap-3">
                                                    <div className="flex items-center justify-center w-4">
                                                        <AttachmentIcon />
                                                    </div>
                                                    <label className="text-xs w-max text-[#808080]">
                                                        Add Image & Video
                                                    </label>
                                                </div>
                                            )}
                                            <input
                                                type="file"
                                                accept="image/*,video/*"
                                                title=""
                                                name="file"
                                                id="upload_file"
                                                className="hidden"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>




                        <div className="w-full flex items-center justify-end">
                            {/* <button type="submit" className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full">
                  Create Store
              </button> */}
                            <input
                                type="submit"
                                value={loading ? "Loading..." : words["Save"]}
                                className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full cursor-pointer"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );



};

export default NewAd;
