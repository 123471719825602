import React from "react";

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#667085"
        d="M14.064 1.087A1.085 1.085 0 0115.149.002 10.863 10.863 0 0126 10.852a1.085 1.085 0 11-2.17 0 8.69 8.69 0 00-8.681-8.68 1.085 1.085 0 01-1.085-1.085zm1.085 5.425a4.34 4.34 0 014.34 4.34 1.085 1.085 0 102.17 0 6.517 6.517 0 00-6.51-6.51 1.085 1.085 0 100 2.17zm9.867 11.654a3.364 3.364 0 010 4.75l-.988 1.138C15.141 32.563-6.485 10.942 1.892 2.027L3.14.94a3.343 3.343 0 014.695.044C7.87 1.018 9.88 3.63 9.88 3.63a3.364 3.364 0 01-.008 4.647l-1.256 1.58a13.869 13.869 0 007.52 7.536l1.59-1.264a3.364 3.364 0 014.645-.007s2.611 2.01 2.645 2.044zm-1.493 1.577s-2.597-1.997-2.63-2.031a1.194 1.194 0 00-1.681 0c-.03.03-2.218 1.774-2.218 1.774a1.085 1.085 0 01-1.063.165 16.286 16.286 0 01-9.571-9.558 1.085 1.085 0 01.157-1.085S8.261 6.818 8.29 6.79a1.194 1.194 0 000-1.68C8.256 5.076 6.26 2.477 6.26 2.477a1.194 1.194 0 00-1.639.042L3.372 3.605c-6.122 7.362 12.619 25.063 19.07 18.967l.988-1.14a1.217 1.217 0 00.093-1.689z"
      ></path>
    </svg>
  );
}

export default PhoneIcon;
