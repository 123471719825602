import React, { useEffect, useState } from "react";
import { CrossIcon, EditIcon, ImageIcon } from "../assets/Icons";
import { toast } from "react-toastify";
import { useAddStaffMutation } from "../store/services/staffService";
import AppLoader from "./Loader/AppLoader";
import { useSelector } from "react-redux";

const AddStaffModel = ({ openModal, setOpenModal }) => {


  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);
  const [addStaff, response] = useAddStaffMutation();
  const [img, setImg] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState();


  const textAlign = lang == 'ar' ? 'text-right' : 'text-left';



  const doAddStaff = () => {
    if (!email) return toast.error('Please enter a valid email');
    if (!password) return toast.error('Please enter password');
    if (!phone) return toast.error('Please enter phone');



    const formData = new FormData();
    formData.append("image", img)
    formData.append("email", email)
    formData.append("password", password)
    formData.append("phone", phone)
    addStaff(formData);

  }

  useEffect(() => {
    console.log('response is here', response)
    if (response.isSuccess) {
      toast.success('Staff added successfully');
      setOpenModal(false);
    }
    if (response.isError) {
      toast.error('Error while adding staff');
    }

  }, [response])

  return (
    <div
      dir={lang == 'ar' ? "rtl" : 'ltr'}
      className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">

      {
        response.isLoading && <AppLoader />
      }
      <div className="flex items-center justify-between gap-3">
        <h1 className="text-black text-xl font-medium">{words["Add Staff"]}</h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex flex-col gap-5 mt-10">


        <div className="flex items-center">
          <div className="field flex flex-col">
            <div
              className="img-box flex items-center justify-center h-40 w-64 bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
              onClick={() => document.getElementById("upload_img").click()}
            >
              {img ? (
                <img
                  alt="img"
                  src={URL.createObjectURL(img)}
                  className="h-full w-full overflow-hidden object-contain"
                />
              ) : (
                <div className="flex items-center justify-center h-full w-full">
                  <ImageIcon />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                title=""
                name="image"
                id="upload_img"
                className="hidden cleanbtn"
                onChange={(e) => {
                  setImg(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>


        <div className="flex flex-col gap-2">
          <label className={`${textAlign} text-black text-sm font-normal`}>{words["Email"]}</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            // placeholder="Enter email"
            className={` ${textAlign} border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm`}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className={`${textAlign} text-black text-sm font-normal`}>{words["Phone"]}</label>
          <input
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            // placeholder="Enter password"
            className={` ${textAlign} border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm`}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className={`${textAlign} text-black text-sm font-normal`}>{words["Password"]}</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            // placeholder="Enter password"
            className={` ${textAlign} border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm`}
          />
        </div>

        {/* <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">Role</label>
          <div className="flex items-center justify-between gap-2">
            <div
              className={`flex flex-1 cursor-pointer border border-[#D9D9D9] py-2 px-2 rounded-lg anim font-normal text-sm ${selectedRole === "Admin"
                ? "bg-themeColor text-white"
                : "text-black/20"
                }`}
              onClick={(e) => setSelectedRole("Admin")}
            >
              Admin
            </div>
            <div
              className={`flex flex-1 cursor-pointer border border-[#D9D9D9] py-2 px-2 rounded-lg anim font-normal text-sm ${selectedRole === "Employee"
                ? "bg-themeColor text-white"
                : "text-black/20"
                }`}
              onClick={(e) => setSelectedRole("Employee")}
            >
              Employee
            </div>
          </div>
        </div> */}

        <div className="flex items-center justify-center">
          <button
            onClick={() => doAddStaff()}
            className="button rounded-full !py-2 !px-10">{words["Save"]}</button>
        </div>
      </div>
    </div>
  );
};

export default AddStaffModel;
