import { API_URL } from './constants';
import axios from 'axios';
import Store from '../store';





function getUrl(route, baseurl) {
    if (baseurl == false) {
        return route;
    } else {
        return `${API_URL}${route}`
    }
}

function getToken() {
    return Store.getState().authReducer?.adminToken;
}

const apiCall = async (method, payload, route, baseurl, onSuccess, onError, stopLoader) => {
    try {
        const url = getUrl(route, baseurl);
        let response = null
        const token = getToken();

        let config = {
            method: method,
            maxBodyLength: Infinity,
            data: payload,
            url: url,
            // token ? { headers: { Authorization: `Bearer ${token}` } } : null,
            headers:
                token ? { Authorization: `Bearer ${token}` } : null,
        };
        response = await axios.request(config);
        if (response?.data.status == 1) {
            onSuccess(response.data);
            return { status: 200, response: response.data };
        } else {
            onError(response?.data?.error);
            return response;
        }
    }
    catch (e) {

        onError(e.response?.data);
        return {
            status: 400,
            response: e?.response?.data ? e?.response?.data : { message: e.toString() },
        };
    }
}

export const getRequest = async (payload, route, baseurl, onSuccess = (res) => { }, onError = (err) => { }, stopLoader = true) => {

    const response = await apiCall('get', payload, route, baseurl, onSuccess, onError, stopLoader);
    return response;


};

export const postRequest = async (payload, route, baseurl, onSuccess = (res) => { }, onError = (err) => { }, stopLoader = true) => {

    const response = await apiCall('post', payload, route, baseurl, onSuccess, onError, stopLoader);
    return response;

};

export const patchRequest = async (payload, route, baseurl, onSuccess = (res) => { }, onError = (err) => { }, stopLoader = true) => {

    const response = await apiCall('patch', payload, route, baseurl, onSuccess, onError, stopLoader);
    return response;

};

export const putRequest = async (payload, route, baseurl, onSuccess = () => { }, onError = () => { }, stopLoader = true) => {


    const response = await apiCall('put', payload, route, baseurl, onSuccess, onError, stopLoader);
    return response;

};

export const deleteRequest = async (payload, route, baseurl, onSuccess = (res) => { }, onError = (err) => { }) => {
    try {
        const url = getUrl(route, baseurl);
        const token = getToken();
        let response = null;
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            data: payload,
            url: url,
            headers:
                token ? { 'x-sh-auth': token } : null
        }
        response = await axios.request(config);

        if (response?.status == 200) {
            onSuccess(response.data);

            return { status: 200, response: response.data };
        } else {
            onError(response);

            return response;
        }
    }
    catch (e) {
        onError(e.response?.data);
        return {
            status: 400,
            response: e?.response?.data ? e?.response?.data : { message: e.toString() },
        };
    }
};

export const putRequestFormData = async (payload, route, baseurl, onSuccess = (res) => { }, onError = (res) => { }, stopLoader = true) => {
    try {

        const url = getUrl(route, baseurl);
        const formData = new FormData();
        const token = getToken();
        let response = null;
        const headers = {
            'Content-Type': 'multipart/form-data',
            'x-sh-auth': token
        };
        for (let key in payload) {
            formData.append(key, payload[key])
        }
        response = await axios.put(url, formData, { headers })
        if (response?.status == 200) {
            onSuccess(response.data);

            return { status: 200, response: response.data };
        } else {
            // console.log('error___', response)
            onError(response);

            return response;
        }
    }
    catch (e) {
        onError(e);
        console.log('__post request form data error', e.response?.data)
        return {
            status: 400,
            response: e?.response?.data ? e?.response?.data : { message: e.toString() },
        };
    }
};


export const postRequestFormData = async (payload, route, baseurl, onSuccess = (res) => { }, onError = (res) => { }, stopLoader = true) => {
    try {

        const url = getUrl(route, baseurl);
        const formData = new FormData();
        const token = getToken();
        console.log('url', url);
        // console.log('here is token',token);
        let response = null;

        for (let key in payload) {
            formData.append(key, payload[key])
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            data: formData,
            url: url,

            headers:
                token ? {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                } : {
                    'Content-Type': 'multipart/form-data',
                },

        }
        // console.log('configs are', config)

        response = await axios.request(config);
        console.log('resp', response.data)
        if (response?.data.status == 1) {
            onSuccess(response.data);
            return { status: 200, response: response.data };
        } else {
            onError(response.data);

            return response;
        }
    }
    catch (e) {
        onError(e.response?.data);
        // console.log('__post request form data error', e)

        // SimpleToast.show(typeof e.response?.data.message == 'string' ? e.response?.data.message : 'Server error');
        return {
            status: 400,
            response: e?.response?.data ? e?.response?.data : { message: e.toString() },
        };
    }
};

