import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const settingService = createApi({
  reducerPath: "setting",
  tagTypes: "settings",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createSetting: builder.mutation({
        query: (data) => {
          return {
            url: "/api/setting/store",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["settings"],
      }),
      getSetting: builder.query({
        query: () => {
          return {
            url: "/api/setting",
            method: "GET",
          };
        },
        providesTags: ["settings"],
      }),
      getGeneral: builder.query({
        query: () => {
          return {
            url: "/api/gernal",
            method: "GET",
          };
        },
        providesTags: ["settings"],
      }),
    };
  },
});
export const {
  useCreateSettingMutation,
  useGetSettingQuery,
  useGetGeneralQuery,
} = settingService;
export default settingService;
