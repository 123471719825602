import React from "react";

function KeyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      fill="none"
      viewBox="0 0 32 30"
    >
      <path
        fill="#667085"
        d="M29.466 1.706A1 1 0 1028.049.294l1.417 1.412zm-4.35 1.532a1 1 0 101.418 1.411l-1.417-1.411zM14.669 15.143l.703-.71a1 1 0 00-1.412.005l.709.705zM3.264 26.591l-.721.693.007.008.714-.701zm11.402-11.446l-.696.718a1 1 0 001.405-.012l-.709-.706zm-.707-.707a1 1 0 101.417 1.411l-1.417-1.411zM21.402 9.8a1 1 0 00-1.417-1.412l1.417 1.412zm0-1.412A1 1 0 1019.985 9.8l1.417-1.411zm3.69 5.12l-.708.707a1 1 0 001.417 0l-.709-.706zm5.132-5.15l.708.706a1 1 0 000-1.412l-.708.706zm-3.69-5.121a1 1 0 10-1.417 1.411l1.417-1.411zm-6.549 5.15A1 1 0 1021.402 9.8L19.985 8.39zm6.549-3.739a1 1 0 10-1.417-1.411l1.417 1.411zM28.049.294l-2.932 2.944 1.417 1.411 2.932-2.943L28.049.294zm-14.085 15.56a7.115 7.115 0 011.867 6.873l1.933.512a9.115 9.115 0 00-2.393-8.806l-1.407 1.421zm1.867 6.873a7.08 7.08 0 01-5.015 5.036l.515 1.932a9.08 9.08 0 006.433-6.456l-1.933-.512zm-5.015 5.036a7.044 7.044 0 01-6.84-1.873l-1.425 1.402a9.044 9.044 0 008.78 2.403l-.515-1.932zm-6.832-1.866a7.116 7.116 0 01.086-9.948l-1.417-1.411c-3.494 3.508-3.543 9.178-.11 12.746l1.441-1.387zm.086-9.948a7.042 7.042 0 019.9-.086l1.392-1.436a9.042 9.042 0 00-12.709.11L4.07 15.95zm11.305-.098l.001-.002-1.417-1.411-.001.001 1.417 1.412zm.001-.002l6.026-6.048-1.417-1.412-6.026 6.049 1.417 1.411zm4.61-6.049l4.398 4.416 1.417-1.412-4.399-4.415L19.985 9.8zm5.815 4.416l5.131-5.151-1.417-1.412-5.131 5.151 1.417 1.412zm5.131-6.563l-4.398-4.415-1.417 1.411 4.398 4.416 1.417-1.412zM21.402 9.8l5.132-5.15-1.417-1.412-5.132 5.15L21.402 9.8z"
      ></path>
    </svg>
  );
}

export default KeyIcon;
