import { Dropdown, Menu, Button, Typography, Image } from "antd";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Layout, ToggleButton, UploadButton } from "../../components";
import { getRequest, postRequest, postRequestFormData } from "../../Api";
import { useSelector } from "react-redux";
import { AttachmentIcon, DotMenuIcon } from "../../assets/Icons";
import { IMAGE_URL } from "../../Api/constants";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { useApproveAdMutation, useRejectAdMutation } from "../../store/services/adsService";
import Modal from "../../components/Modal";
import RejectedReasonModel from "../../components/RejectedReasonModel";
import AppLoader from "../../components/Loader/AppLoader";
import { routes } from "../../Api/routes";




const ViewPost = () => {

    const words = useSelector((state) => state.authReducer.words);
    const lang = useSelector((state) => state.authReducer.lang);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [rejectedAdModel, setRejectedAdModel] = useState(false);

    const [fileType, setFileType] = useState('');
    const [file, setFile] = useState(null);

    const [data, setData] = useState({});

    const [
        approveAd,
        {
            isLoading: adApproveLoading,
            isSuccess: approveAdSuccess,
            isError: approveError,
        },
    ] = useApproveAdMutation();
    const [
        rejectAd,
        {
            isLoading: adRejectLoading,
            isSuccess: rejectAdSuccess,
            isError: rejectError,
            response,
        },
    ] = useRejectAdMutation();




    const details = [
        // {
        //     label: words["Title"],
        //     value: `${data?.title}`,

        // },
        // {
        //     label: words["Description"],
        //     value: data.description
        // },

        {
            label: words["Status"],
            value: capitalizeFirstLetter(words[data.status]),
            svg: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.55 22.42C14.22 22.42 13.91 22.2 13.82 21.86C13.72 21.46 13.95 21.05 14.35 20.94C18.41 19.87 21.24 16.19 21.24 11.99C21.24 6.89 17.09 2.74 11.99 2.74C7.66 2.74 4.82 5.27 3.49 6.8H6.43C6.84 6.8 7.18 7.14 7.18 7.55C7.18 7.96 6.86 8.31 6.44 8.31H2.01C1.94 8.31 1.87 8.3 1.8 8.28C1.71 8.25 1.63 8.21 1.56 8.16C1.47 8.1 1.4 8.02 1.35 7.93C1.3 7.83 1.26 7.73 1.25 7.62C1.25 7.59 1.25 7.57 1.25 7.54V3C1.25 2.59 1.59 2.25 2 2.25C2.41 2.25 2.75 2.59 2.75 3V5.39C4.38 3.64 7.45 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 16.88 19.46 21.16 14.74 22.4C14.68 22.41 14.61 22.42 14.55 22.42Z" fill="white" />
                <path d="M11.29 22.73C11.27 22.73 11.24 22.73 11.22 22.72C10.15 22.65 9.1 22.41 8.1 22.02C7.81 21.91 7.62 21.62 7.62 21.31C7.62 21.22 7.64 21.13 7.67 21.05C7.82 20.67 8.26 20.47 8.64 20.62C9.5 20.96 10.41 21.16 11.33 21.23H11.34C11.74 21.25 12.04 21.59 12.04 21.99C12.04 22 12.04 22.02 12.04 22.03C12.01 22.42 11.68 22.73 11.29 22.73ZM5.78 20.58C5.61 20.58 5.45 20.53 5.31 20.42C4.47 19.75 3.74 18.96 3.13 18.07C3.04 17.94 2.99 17.8 2.99 17.65C2.99 17.4 3.11 17.17 3.32 17.03C3.65 16.8 4.14 16.89 4.36 17.22C4.89 17.99 5.52 18.67 6.25 19.24C6.42 19.38 6.53 19.59 6.53 19.82C6.53 19.99 6.48 20.16 6.37 20.3C6.23 20.48 6.01 20.58 5.78 20.58ZM2.44 15.7C2.11 15.7 1.82 15.49 1.73 15.18C1.41 14.15 1.25 13.08 1.25 12C1.25 11.59 1.59 11.25 2 11.25C2.41 11.25 2.75 11.59 2.75 12C2.75 12.93 2.89 13.85 3.16 14.73C3.18 14.8 3.19 14.88 3.19 14.96C3.19 15.29 2.98 15.57 2.67 15.67C2.59 15.69 2.52 15.7 2.44 15.7Z" fill="white" />
                <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="white" />
            </svg>

        },

        {
            label: words["Category"],
            value: words[data.category],
            svg: <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3005 14.6552H10.3448C9.8601 14.6552 9.45813 14.2566 9.45813 13.7759C9.45813 13.2952 9.8601 12.8966 10.3448 12.8966H13.3005C16.398 12.8966 18.9163 10.3993 18.9163 7.32759C18.9163 4.25586 16.398 1.75862 13.3005 1.75862H7.38916C4.29163 1.75862 1.7734 4.25586 1.7734 7.32759C1.7734 8.61724 2.23448 9.87173 3.06207 10.8683C3.36946 11.2434 3.32217 11.7945 2.94384 12.111C2.56552 12.4159 2.00985 12.369 1.69064 11.9938C0.602956 10.6807 0 9.02759 0 7.32759C0 3.28276 3.31034 0 7.38916 0H13.3005C17.3793 0 20.6897 3.28276 20.6897 7.32759C20.6897 11.3724 17.3793 14.6552 13.3005 14.6552Z" fill="white" />
                <path d="M17.9114 22.4137H12.2496C8.34297 22.4137 5.17236 19.3241 5.17236 15.5172C5.17236 11.7103 8.34297 8.62061 12.2496 8.62061H15.0805C15.5448 8.62061 15.9298 8.99578 15.9298 9.44819C15.9298 9.90061 15.5448 10.2758 15.0805 10.2758H12.2496C9.28282 10.2758 6.8709 12.6261 6.8709 15.5172C6.8709 18.4082 9.28282 20.7585 12.2496 20.7585H17.9114C20.8782 20.7585 23.2901 18.4082 23.2901 15.5172C23.2901 14.3034 22.8485 13.1227 22.0558 12.1847C21.7614 11.8316 21.8067 11.313 22.1691 11.0151C22.5314 10.7172 23.0636 10.7723 23.3694 11.1254C24.4224 12.3613 25 13.9172 25 15.5172C24.9886 19.3241 21.818 22.4137 17.9114 22.4137Z" fill="white" />
            </svg>

        },

        {
            label: words["Posted by"],
            value: data?.user?.name,
            svg: <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 24C4.11439 20.6482 8.34269 18.5882 13 18.5882C17.6573 18.5882 21.8856 20.6482 25 24M19 7.08824C19 10.4507 16.3137 13.1765 13 13.1765C9.68629 13.1765 7 10.4507 7 7.08824C7 3.7258 9.68629 1 13 1C16.3137 1 19 3.7258 19 7.08824Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        },

        {
            label: words["Created at"],
            value: getDateString(data?.created_at),
            svg: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 2V5" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 2V5" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.5 9.08984H20.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.9955 13.7002H12.0045" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.29431 13.7002H8.30329" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.29431 16.7002H8.30329" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        },

        {
            label: words["Rejected reason"],
            value: data?.rejected_reason,
        },

    ];



    const getPostDetails = async () => {
        const onSuccess = (res) => {
            const data = res.data ?? {};
            setData(res.data ?? {});
            setFile(data.file);
            setFileType(data.file_type);
            setLoading(false);
        }
        const onError = (err) => {
            toast.error(typeof err == 'string' ? err : "Something went wrong");
            setLoading(false);
            console.log('this is the res', err);
        }

        const route = 'admin/get_post_details/' + id;
        setLoading(true);
        await getRequest('', route, true, onSuccess, onError);


    }




    const doChangePostStatus = async (status, reason = null) => {
        setRejectedAdModel(false);

        const onSuccess = (res) => {
            setLoading(false);
            getPostDetails();

            // getDashboard();
        };

        const onError = (err) => {
            setLoading(false);
            setLoading(false);
            toast.error(err?.error);
        };

        const body = {
            status: status,
            id: id,
        };
        if (status == "rejected") body.rejected_reason = reason;
        setLoading(true);
        await postRequest(body, routes.updatePostStatus, true, onSuccess, onError);
    };


    useEffect(() => {
        getPostDetails();
    }, [])


    const justfyAction = lang == 'ar' ? 'justify-end' : 'justify-end'
    const marginX = lang == 'ar' ? 'ml-4' : 'mr-4'

    return (
        <>
            {
                adApproveLoading || adRejectLoading || loading ? <AppLoader /> : null
            }

            <Layout pageTitle={words["Ad details"]}>
                <div className="w-full min-h-screen flex pb-10">
                    <div className="wrap wrapWidth flex flex-col gap-6">
                        <div className="w-full flex flex-col gap-8 grid-box px-5 py-5 rounded-[20px]">
                            <div className={`flex items-center ${justfyAction}`}>
                                <label className={`text-lg font-semibold text-gray-700 ${marginX}`}>{words["Action"]}</label>
                                <Dropdown
                                    overlay={

                                        <Menu
                                            onClick={({ key }) => {
                                                if (key == 'rejected') return setRejectedAdModel(true);
                                                doChangePostStatus(key);
                                            }}
                                        >
                                            <>
                                                <Menu.Item style={{ fontFamily: 'MMe' }} key="rejected">{words["Reject"]}</Menu.Item>
                                                <Menu.Item style={{ fontFamily: 'MMe' }} key="published">{words["Published"]}</Menu.Item>
                                                <Menu.Item style={{ fontFamily: 'MMe' }} key="in-review">{words["In-Review"]}</Menu.Item>
                                            </>
                                        </Menu>

                                    }
                                    trigger={['click']}
                                >
                                    <Button>
                                        <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
                                            <DotMenuIcon className="w-6 h-6" />
                                        </div>
                                    </Button>
                                </Dropdown>
                            </div>

                            <div className="w-full grid grid-cols-3 gap-6">
                                <div className="col-span-2 grid grid-cols-2 gap-6">
                                    {details.map((item, index) => (
                                        <div key={index} className="w-full flex items-center gap-2.5">
                                            <div className="h-[50px] w-[50px] bg-themeColor/70 rounded-lg flex justify-center items-center ">
                                                {item.svg}

                                            </div>
                                            <div className="flex flex-col">
                                                <div style={{ fontFamily: 'MBo' }} className="text-base font-semibold">{item.label}</div>
                                                <div style={{ fontFamily: 'MMe' }} className="text-[14px] text-[#A7A3A0]">{item.value}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex justify-center ">
                                    <div className="img-box h-60 w-full bg-[#EFEFEF] border border-[#808080] rounded-lg relative">
                                        {file ? (
                                            fileType === 'image' ? (
                                                <img
                                                    alt="file"
                                                    src={IMAGE_URL + file}
                                                    className="h-full w-full overflow-hidden object-contain"
                                                />
                                            ) : (
                                                <video
                                                    controls
                                                    className="h-full w-full object-contain"
                                                >
                                                    <source src={IMAGE_URL + file} />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex flex-col gap-6">
                                <div className="field flex flex-col w-full">
                                    <div className="text-lg font-semibold mb-2" style={{ fontFamily: 'MSBo' }} >{words["Title"]}</div>
                                    <div className="border rounded-lg p-4 bg-white shadow-md">
                                        <Typography.Paragraph style={{ fontFamily: 'MBo', color: '#808080' }} >{data?.title}</Typography.Paragraph>
                                    </div>
                                </div>
                                <div className="field flex flex-col w-full">
                                    <div className="text-lg font-semibold mt-4 mb-2" style={{ fontFamily: 'MSBo' }}  >{words["Description"]}</div>
                                    <div className="border rounded-lg p-4 bg-white shadow-md h-full">
                                        <Typography.Paragraph style={{ fontFamily: 'MBo', color: '#808080' }} >{data.description}</Typography.Paragraph>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Modal open={rejectedAdModel} setOpen={setRejectedAdModel}>
                            <RejectedReasonModel
                                openModal={rejectedAdModel}
                                setOpenModal={setRejectedAdModel}
                                onSubmit={(reason) =>
                                    doChangePostStatus("rejected", reason)
                                }
                            />
                        </Modal>
                    </div>
                </div>
            </Layout>
        </>
    )
    return (
        <>
            {
                adApproveLoading || adRejectLoading || loading ? <AppLoader /> : null
            }
            <Layout pageTitle={words["Post details"]}>
                <div className="w-full min-h-screen flex pb-10">
                    <div className="wrap wrapWidth flex flex-col gap-6">

                        <div className="w-full flex flex-col gap-8 grid-box px-5 py-5 rounded-[20px]">
                            <div className={`flex items-center ${justfyAction} `} >
                                <label className={`text-lg font-semibold text-gray-700 ${marginX}`}>{words["Action"]}</label>
                                <Dropdown
                                    overlay={
                                        <Menu
                                            onClick={({ key }) => {
                                                doChangePostStatus(key);
                                            }}
                                        >
                                            <>
                                                <Menu.Item key="rejected">{words["Reject"]}</Menu.Item>
                                                <Menu.Item key="published">{words["Published"]}</Menu.Item>
                                                <Menu.Item key="in-review">{words["In-Review"]}</Menu.Item>
                                            </>
                                        </Menu>
                                    }
                                    trigger={["click"]}
                                >
                                    <Button>
                                        <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
                                            <DotMenuIcon />
                                        </div>
                                    </Button>
                                </Dropdown>
                            </div>

                            <div className="w-full flex items-center">
                                <div className="text-[#18120F] text-xl font-bold flex-1">
                                    {words["Ad Details"]}
                                </div>

                            </div>
                            <div className="w-full grid grid-cols-3 items-start gap-x-3 gap-y-4 flex-1">
                                {details.map((item, index) => (
                                    <div key={index} className="w-full flex items-center gap-2.5">
                                        <div className="h-[50px] w-[50px] bg-themeColor/70 rounded-lg"></div>
                                        <div className="flex flex-col">
                                            <div className="text-base font-semibold">{item.label}</div>
                                            <div className="text-[14px] text-[#A7A3A0]">{item.value}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="w-full flex justify-between">

                                <div className="field flex flex-col w-full">
                                    <div
                                        className="img-box flex items-center justify-center h-40 w-full bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                                    >
                                        {file ? (
                                            fileType === 'image' ? (
                                                <Image
                                                    src={IMAGE_URL + file}
                                                    style={{ width: 70, }}
                                                    className="h-full w-full overflow-hidden object-contain"
                                                />
                                                // <img
                                                //     alt="file"
                                                //     src={IMAGE_URL + file}
                                                //     className="h-full w-full overflow-hidden object-contain"
                                                // />
                                            ) : (
                                                <video
                                                    controls
                                                    className="h-full w-full object-contain"
                                                >
                                                    <source
                                                        src={IMAGE_URL + file}
                                                    // type={'.mp4'}
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )
                                        ) : (
                                            <div className="flex items-center justify-between gap-3">
                                                <div className="flex items-center justify-center w-4">
                                                    <AttachmentIcon />
                                                </div>
                                                <label className="text-xs w-max text-[#808080]">
                                                    Add Image & Video
                                                </label>
                                            </div>
                                        )}

                                    </div>
                                </div>








                            </div>
                        </div>

                        <Modal open={rejectedAdModel} setOpen={setRejectedAdModel}>
                            <RejectedReasonModel
                                openModal={rejectedAdModel}
                                setOpenModal={setRejectedAdModel}
                                onSubmit={(reason) =>
                                    doChangePostStatus("rejected", reason)
                                }
                            />
                        </Modal>

                        {/* <form className="w-full gap-6">
                        <div className="w-full flex gap-6 mb-6">
                            <div className="w-full grid-box px-5 pt-4 pb-12 border-solid border-[1px] border-[#D8D8D8] rounded-[20px] flex flex-col">
                                <div className="w-full flex items-center mb-6">
                                    <div className="text-base font-bold flex-1">
                                        {words["Ad information"]}
                                    </div>
                                </div>
                                <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                    <div className="text-base text-black">{words["Title"]}</div>
                                    <Input
                                        disabled
                                        placeholder={words["Title"]}
                                        name="title"
                                        value={formData.title}
                                        onChange={handleInput}
                                        required
                                    />
                                </div>


                                <div className="flex flex-col gap-2">
                                    <div className="text-base">{words["Description"]}</div>
                                    <textarea
                                        disabled
                                        className="input h-[110px]"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInput}
                                        placeholder="Enter About Store here..."
                                    />
                                </div>


                                <div className="flex flex-col gap-2 mt-5">
                                    <label className="text-black text-sm font-normal">
                                        {words["Category"]}
                                    </label>
                                    <Input
                                        disabled
                                        name="title"
                                        value={words[formData.ad_category]}
                                        onChange={handleInput}
                                        required
                                    />
                                </div>

                                <div className="flex flex-col gap-2 mt-5">
                                    <label className="text-black text-sm font-normal">{words["Duration"]}</label>
                                    <Input
                                        disabled
                                        name="title"
                                        value={words[formData.ad_duration]}
                                        onChange={handleInput}
                                        required
                                    />
                                </div>


                            </div>

                            <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
                                <div className="w-full flex items-center mb-6">
                                    <div className="text-base font-bold flex-1">{words["Ad video or image"]}</div>
                                </div>




                                <div className="flex items-center w-full">
                                    <div className="field flex flex-col w-full">
                                        <div
                                            className="img-box flex items-center justify-center h-40 w-full bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                                            onClick={handleClick}
                                        >
                                            {file ? (
                                                fileType === 'image' ? (
                                                    <img
                                                        alt="file"
                                                        src={IMAGE_URL + file}
                                                        className="h-full w-full overflow-hidden object-contain"
                                                    />
                                                ) : (
                                                    <video
                                                        controls
                                                        className="h-full w-full object-contain"
                                                    >
                                                        <source
                                                            src={IMAGE_URL + file}
                                                        // type={'.mp4'}
                                                        />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )
                                            ) : (
                                                <div className="flex items-center justify-between gap-3">
                                                    <div className="flex items-center justify-center w-4">
                                                        <AttachmentIcon />
                                                    </div>
                                                    <label className="text-xs w-max text-[#808080]">
                                                        Add Image & Video
                                                    </label>
                                                </div>
                                            )}
                                          
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>




                        <div className="w-full flex items-center justify-end">
                           
                            <input
                                type="submit"
                                value={loading ? "Loading..." : words["Save"]}
                                className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full cursor-pointer"
                            />
                        </div>
                    </form> */}
                    </div>
                </div >
            </Layout >
        </>
    );



};

export default ViewPost;
