import React, { useEffect, useState } from "react";
import { CrossIcon } from "../assets/Icons";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useSendNotificationMutation } from "../store/services/notificationService";
import { useSelector } from "react-redux";
// import AppLoader from "./Loader/AppLoader";

const RejectedReasonModel = ({ openModal, setOpenModal, onSubmit = () => { } }) => {


  const [rejectedReason, setRejectedReason] = useState("");

  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);

  const textAlign = lang == 'ar' ? 'text-right' : 'text-left';


  return (
    <div
      dir={lang == 'ar' ? "rtl" : "ltr"}
      className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex items-center justify-between gap-3 mb-5">
        <h1 className="text-black text-lg font-medium">{words["Rejected Reason"]}</h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div
        className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label

            className={`text-black text-sm font-normal ${textAlign}`}>{words["Enter rejected reason"]}</label>
          <textarea
            placeholder={words["Rejected reason"]}
            value={rejectedReason}
            onChange={(e) => setRejectedReason(e.target.value)}
            className="min-h-[110px] max-h-[200px] border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>


        <div className="flex items-center justify-center">
          <button
            className="button rounded-full !py-2 !px-10" onClick={() => {
              if (!rejectedReason) return toast.error('Please enter a valid rejected reason');
              onSubmit(rejectedReason);
            }}>
            {words["Reject"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectedReasonModel;


