import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { postRequest, postRequestFormData } from "../../Api";
import { routes } from "../../Api/routes";
import { toast } from "react-toastify";
import { Layout, ToggleButton } from "../../components";
import { useSelector } from "react-redux";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { EditIcon, ImageIcon } from "../../assets/Icons";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const words = useSelector((state) => state.authReducer.words);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState();

  const [img, setImg] = useState();
  const [localImg, setLocalImg] = useState();




  // const [img, setImg] = useState();

  const getUser = async () => {
    const onSuccess = (res) => {
      console.log("res", res.data);
      setName(res.data?.name);
      setNumber(res.data?.number);
      setImg(res.data?.image);
      setStatus(res.data?.status)
      // setType(res.data?.type);
      setLoading(false);
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };
    const route = routes.getUser + "/" + id;

    setLoading(true);
    await postRequest("", route, true, onSuccess, onError);
  };

  const doEditUser = async () => {
    if (!name) return toast.error("Please enter name");
    if (!number) return toast.error("Please enter number");
    // if (!type) return toast.error("Please enter type");
    if (!img && !localImg) return toast.error("Please add image");

    const onSuccess = (res) => {
      setLoading(false);
      toast.success("User updated successfully");
      navigate("/dashboard/users");
      return;
    };

    const onError = (err) => {
      typeof err == 'string' && toast.error(err);
      setLoading(false);
      toast.error(err?.error);
    };

    const body = {
      name: name,
      number: number,
      // type: type,
      image: localImg ?? null,
      id: id,
      status: status ? 1 : 0
    };
    setLoading(true);
    await postRequestFormData(body, routes.editUser, true, onSuccess, onError);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Layout pageTitle="Edit user">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
          <div className="flex flex-col gap-5 mt-10">


            <div
              className="img-box flex flex-col items-center justify-center h-28 w-28 rounded-full cursor-pointer bg-[#D9D9D9] relative"
              onClick={() => document.getElementById("upload_img").click()}
            >
              {localImg ? (
                <img
                  alt="img"
                  src={URL.createObjectURL(localImg)}
                  className="h-full w-full rounded-full object-contain"
                />
              ) : (

                <img
                  // alt="banner image"
                  src={IMAGE_URL + img}
                  className="h-full w-full rounded-full object-contain"
                />


                // <div
                //    className="flex items-center justify-center h-full w-full ">
                //   <ImageIcon />
                // </div>
              )}
              <input
                type="file"
                accept="image/*"
                title="image"
                id="upload_img"
                className="hidden cleanbtn"
                onChange={(e) => {
                  setLocalImg(e.target.files[0]);
                }}
              />
              <div className="flex items-center justify-center absolute bg-white border border-themeColor h-8 w-8 p-[6px] rounded-full bottom-0 right-0 z-40">
                <EditIcon />
              </div>
            </div>








            <div className="flex flex-col gap-2">
              <label className="text-black text-sm font-normal">
                {words["Name"]}
              </label>
              <input
                required
                onChange={(t) => setName(t.target.value)}
                value={name}
                type="text"
                placeholder="Enter Name"
                className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
              />
            </div>
            {/* <div className="flex flex-col gap-2">
                    <label className="text-black text-sm font-normal">Email</label>
                    <input
                        type="text"
                        placeholder="Enter Email"
                        className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
                    />
                </div> */}
            <div className="flex flex-col gap-2">
              <label className="text-black text-sm font-normal">
                {words["Phone Number"]}
              </label>
              <input
                required
                onChange={(t) => setNumber(t.target.value)}
                value={number}
                type="text"
                placeholder="Enter Phone Number"
                className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
              />
            </div>

            <div className="flex items-center justify-between gap-3 w-20">
              <h1 className="text-black text-xl font-medium">
                {words["Status"]}
              </h1>
              <div className="flex items-center gap-1.5">
                <div className="text-base">{words[status == 1 ? "Active" : 'Inactive']}</div>
                <ToggleButton checked={status == 1} setChecked={setStatus} />
              </div>
            </div>


            {/* <div className="flex flex-col gap-2">
              <label className="text-black text-sm font-normal">
                {words["User type"]}
              </label>
              <input
                required
                onChange={(t) => setType(t.target.value)}
                value={type}
                type="text"
                placeholder="Enter user type"
                className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
              />
            </div> */}

            <div className="flex items-center justify-center">
              <button
                onClick={() => doEditUser()}
                className="button rounded-full !py-2 !px-10"
              >
                {words["Save"]}
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default EditUser;
