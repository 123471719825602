import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Private from "./Private";
import Public from "./Public";

import Login from "../Pages/Login";
import Registration from "../Pages/Registration";

import Home from "../Pages/Home";
import News from "../Pages/News";
import StoreDetail from "../Pages/StoreDetail";
import Booking from "../Pages/Booking";
import BookingDetail from "../Pages/Booking/BookingDetail";
import Users from "../Pages/Users";
import Subscriptions from "../Pages/Subscriptions";
import Finance from "../Pages/Finance";
import FinanceDetail from "../Pages/FinanceDetail";
import Banner from "../Pages/Banner";
import Featured from "../Pages/Featured";
import Notification from "../Pages/Notification";
import Setting from "../Pages/Setting";
import Staff from "../Pages/Staff";
import ServiceDetail from "../Pages/ServiceDetail";
import ProductDetail from "../Pages/ProductDetail";
import StoreCreate from "../Pages/StoreCreate";
import EditUser from "../Pages/Users/EditUser";
import Ads from "../Pages/Ads";
import { useSelector } from "react-redux";
import NewAd from "../Pages/Ads/NewAd";
import ViewAd from "../Pages/Ads/ViewAd";
import ViewPost from "../Pages/News/ViewPost";
import MyProfile from "../Pages/MyProfile/MyProfile";
import InvoiceDetails from "../Pages/Finance/InvoiceDetails";

const Routing = () => {
  // localStorage.setItem('admin', null);
  const { adminToken } = useSelector((state) => state.authReducer);
  // const isAdmin = localStorage.getItem("admin")? JSON.parse(localStorage.getItem("admin")):null;
  const adminDataString = localStorage.getItem("admin");
  let isAdmin = null;
  if (adminDataString) {
    try {
      // Attempt to parse the stored string as JSON
      isAdmin = JSON.parse(adminDataString);
    } catch (error) {
      // Handle parsing error (if the stored value is not valid JSON)
      console.error('Error parsing admin data:', error);
    }
  }


  useEffect(() => {

    if (!adminToken && window.location.pathname !== "/") {
      window.location.pathname = "/";
    }
    if (!isAdmin && window.location.pathname !== "/") {
      window.location.pathname = "/";
    }
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer position="top-center" />

      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="register"
            element={
              <Public>
                <Registration />
              </Public>
            }
          />
        </Route>
        {isAdmin?.type == "admin" && (
          <Route path="dashboard">
            <Route
              path="home"
              element={
                <Private>
                  <Home />
                </Private>
              }
            />
            <Route
              path="news"
              element={
                <Private>
                  <News />
                </Private>
              }
            />
            <Route
              path="post-details/:id"
              element={
                <Private>
                  <ViewPost />
                </Private>
              }
            />

            {/*   <Route
              path="store-detail"
              element={
                <Private>
                  <StoreDetail />
                </Private>
              }
            />
            <Route
              path="service-detail"
              element={
                <Private>
                  <ServiceDetail />
                </Private>
              }
            />
            <Route
              path="product-detail"
              element={
                <Private>
                  <ProductDetail />
                </Private>
              }
            />
            <Route
              path="booking"
              element={
                <Private>
                  <Booking />
                </Private>
              }
            />
            <Route
              path="booking/:id"
              element={
                <Private>
                  <BookingDetail />
                </Private>
              }
            /> */}
            <Route
              path="users"
              element={
                <Private>
                  <Users />
                </Private>
              }
            />

            <Route
              path="edit-user/:id"
              element={
                <Private>
                  <EditUser />
                </Private>
              }
            />

            <Route
              path="subscriptions"
              element={
                <Private>
                  <Subscriptions />
                </Private>
              }
            />
            <Route
              path="finance"
              element={
                <Private>
                  <Finance />
                </Private>
              }
            />
            <Route
              path="finance-detail"
              element={
                <Private>
                  <FinanceDetail />
                </Private>
              }
            />
            <Route
              path="banner"
              element={
                <Private>
                  <Banner />
                </Private>
              }
            />
            <Route
              path="featured"
              element={
                <Private>
                  <Featured />
                </Private>
              }
            />
            <Route
              path="notification"
              element={
                <Private>
                  <Notification />
                </Private>
              }
            />
            <Route
              path="staff"
              element={
                <Private>
                  <Staff />
                </Private>
              }
            />
            <Route
              path="setting"
              element={
                <Private>
                  <Setting />
                </Private>
              }
            />

            <Route
              path="ads"
              element={
                <Private>
                  <Ads />
                </Private>
              }
            />
            <Route
              path="new-ad"
              element={
                <Private>
                  <NewAd />
                </Private>
              }
            />
            <Route
              path="ad-details/:id"
              element={
                <Private>
                  <ViewAd />
                </Private>
              }
            />
            <Route
              path="profile"
              element={
                <Private>
                  <MyProfile />
                </Private>
              }
            />
            <Route
              path="invoice"
              element={
                <Private>
                  <InvoiceDetails />
                </Private>
              }
            />
          </Route>
        )}
        {isAdmin?.type == "employee" && (
          <Route path="dashboard">
            <Route
              path="home"
              element={
                <Private>
                  <Home />
                </Private>
              }
            />
            <Route
              path="news"
              element={
                <Private>
                  <News />
                </Private>
              }
            />
            <Route
              path="post-details/:id"
              element={
                <Private>
                  <ViewPost />
                </Private>
              }
            />
            <Route
              path="booking"
              element={
                <Private>
                  <Booking />
                </Private>
              }
            />
            <Route
              path="booking/:id"
              element={
                <Private>
                  <BookingDetail />
                </Private>
              }
            />

            <Route
              path="ads"
              element={
                <Private>
                  <Ads />
                </Private>
              }
            />

            <Route
              path="ad-details/:id"
              element={
                <Private>
                  <ViewAd />
                </Private>
              }
            />


          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
