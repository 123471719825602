import { convertToRaw, EditorState, ContentState } from "draft-js";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import { routes } from "../Api/routes";
import { postRequest } from "../Api";
import { stateToHTML } from "draft-js-export-html";

export default function Index({ initialContent = '', showToolbar = true, isReadOnly = false, onChange = () => { } }) {
  // Initial content as HTML


  // Create an EditorState with the initial content
  const contentState = stateFromHTML(initialContent);
  const initialEditorState = EditorState.createWithContent(contentState);

  const [editorState, setEditorState] = useState(initialEditorState);
  const [text, setText] = useState();
  // const [isReadOnly, setIsReadOnly] = useState(false);
  // const [showToolbar, setShowToolbar] = useState(true);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    // const text = editorState.getCurrentContent().getPlainText("\u0001");
    // setText(text);
    const content = editorState.getCurrentContent();
    const htmlContent = stateToHTML(content);
    onChange(htmlContent);
  };







  // const toggleReadOnly = () => {
  //   setIsReadOnly(!isReadOnly);
  // };

  // const toggleToolbar = () => {
  //   setShowToolbar(!showToolbar);
  // };

  return (
    <div >
      {/* <button onClick={toggleReadOnly}>
        {isReadOnly ? "Enable Editor" : "Disable Editor"}
      </button>
      <button onClick={toggleToolbar}>
        {showToolbar ? "Hide Toolbar" : "Show Toolbar"}
      </button> */}

      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        readOnly={isReadOnly}
        toolbarHidden={!showToolbar}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: [
            { text: "APPLE", value: "apple" },
            { text: "BANANA", value: "banana", url: "banana" },
            { text: "CHERRY", value: "cherry", url: "cherry" },
            { text: "DURIAN", value: "durian", url: "durian" },
            { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
            { text: "FIG", value: "fig", url: "fig" },
            { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
            { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
          ],
        }}
      />


      {/* <div style={{ height: "80px", overflow: "auto" }}>{text}</div> */}
    </div>
  );
}
