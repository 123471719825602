import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeIcon,
  StoreIcon,
  BooksIcon,
  NewsIcon,
  UsersIcon,
  SubscriptionIcon,
  FinanceIcon,
  BannerIcon,
  BellIcon,
  SettingIcon,
  LogoutIcon,
} from "../../assets/Icons";
import { setAdminToken } from "../../store/reducers/authReducer";
const Sidebar = ({ openSidebar, setOpenSidebar }) => {


  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navBarItems =
    isAdmin.type == "employee"
      ? [
        { lbl: words["Home"], icon: <HomeIcon />, slug: "/dashboard/home" },
        { lbl: words["Posts"], icon: <NewsIcon />, slug: "/dashboard/news" },
        // { lbl: "Users", icon: <UsersIcon />, slug: "/dashboard/users" },
        { lbl: words["Ads"], icon: <UsersIcon />, slug: "/dashboard/ads" },
        // { lbl: "Finance", icon: <FinanceIcon />, slug: "/dashboard/finance" },
      ]
      : [
        { lbl: words["Home"], icon: <HomeIcon />, slug: "/dashboard/home" },
        {
          lbl: words["Posts"],
          icon: <NewsIcon />,
          slug: "/dashboard/news",
        },
        {
          lbl: words["Users"],
          icon: <UsersIcon />,
          slug: "/dashboard/users",
        },
        { lbl: words["Ads"], icon: <UsersIcon />, slug: "/dashboard/ads" },
        {
          lbl: words["Finance"],
          icon: <FinanceIcon />,
          slug: "/dashboard/finance",
        },
      ];

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.setItem(null);
    // dispatch(setAdminToken(null));
    window.location.reload();
    navigate("/");
  };
  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      // setOpenSidebar(false);
    });
  }, []);

  return (
    <div
      style={lang == 'ar' ? { right: 0 } : { left: 0 }}
      className={`sidebar-s relative anim ${openSidebar ? "show" : "hide"}`}>
      <div
        className={`side-block flex flex-col anim rounded-xl ${openSidebar ? "show" : "hide"
          }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="hdr flex items-center justify-center">
          <img src="/images/logo-w.svg" className=" h-16" />
        </div>
        <div className="flex flex-col px-4 h-full">
          <div className="items flex flex-col w-full gap-2">
            {navBarItems.map((item, index) => (
              <NavLink
                key={index}
                to={item.slug}
                className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
              // onClick={(e) => {
              //   setOpenSidebar(false);
              // }}
              >
                <div className="icon flex items-start justify-center">
                  {item.icon}
                </div>
                <div className="li text-white">{item.lbl}</div>
              </NavLink>
            ))}
          </div>

          {isAdmin.type == "admin" && (
            <>
              <div className="flex w-full border-b border-white" />

              <div className="items flex flex-col w-full gap-2">
                <NavLink
                  to="/dashboard/banner"
                  className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
                >
                  <div className="icon flex items-start justify-center">
                    <BannerIcon />
                  </div>
                  <div className="li text-white">{words["Banner"]}</div>
                </NavLink>

                <NavLink
                  to="/dashboard/notification"
                  className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
                >
                  <div className="icon flex items-start justify-center">
                    <BellIcon />
                  </div>
                  <div className="li text-white">{words["Notification"]}</div>
                </NavLink>
                <NavLink
                  to="/dashboard/staff"
                  className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
                >
                  <div className="icon flex items-start justify-center">
                    <UsersIcon />
                  </div>
                  <div className="li text-white">{words["Staff"]}</div>
                </NavLink>
                <NavLink
                  to="/dashboard/setting"
                  className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
                >
                  <div className="icon flex items-start justify-center">
                    <SettingIcon />
                  </div>
                  <div className="li text-white">{words["Setting"]}</div>
                </NavLink>
              </div>
            </>
          )}
        </div>
        <div className="sidebar-footer">
          <button
            className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
            onClick={(e) => logout()}
          >
            <div className="icon flex items-start justify-center">
              <LogoutIcon />
            </div>
            <div className="li text-white">Logout</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
