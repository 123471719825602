import React from "react";

function EyeIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        fill="#fff"
        d="M13.572 4.495C12.668 3.022 10.611.551 7 .551 3.39.55 1.332 3.022.428 4.495a2.862 2.862 0 000 3.01C1.332 8.978 3.389 11.449 7 11.449c3.61 0 5.668-2.471 6.572-3.944a2.862 2.862 0 000-3.01zm-.994 2.4c-.777 1.263-2.535 3.388-5.578 3.388S2.199 8.158 1.422 6.895a1.702 1.702 0 010-1.79C2.199 3.843 3.957 1.718 7 1.718s4.801 2.123 5.578 3.389a1.702 1.702 0 010 1.789z"
      ></path>
      <path
        fill="#fff"
        d="M7 3.084a2.916 2.916 0 100 5.832 2.916 2.916 0 000-5.832zM7 7.75a1.75 1.75 0 110-3.498 1.75 1.75 0 010 3.498z"
      ></path>
    </svg>
  );
}

export default EyeIcon2;
