import React, { useState } from "react";
import Select from "react-select";
import { AttachmentIcon, CrossIcon, ImageIcon } from "../assets/Icons";

const AddAdModel = ({ openModal, setOpenModal }) => {
  const [img, setImg] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "Home", label: "Home" },
    { value: "Food", label: "Food" },
    { value: "Hotels", label: "Hotels" },
    { value: "Properties", label: "Properties" },
    { value: "DiscountsCareers", label: "DiscountsCareers" },
    { value: "News", label: "News" },
    {
      value: "Pictures of the Supreme Leader and his family",
      label: "Pictures of the Supreme Leader and his family",
    },
    { value: "Decrees", label: "Decrees" },
    { value: "Omani projects", label: "Omani projects" },
    { value: "Places in Oman", label: "Places in Oman" },
    { value: "Disaster awareness", label: "Disaster awareness" },
    { value: "Omani history", label: "Omani history" },
    { value: "Oman men", label: "Oman men" },
    { value: "Oman Vision 2040", label: "Oman Vision 2040" },
    { value: "Sports in Oman", label: "Sports in Oman" },
    { value: "Omani personalities", label: "Omani personalities" },
    { value: "The Palestinian cause", label: "The Palestinian cause" },
  ];

  const durationsList = [
    { id: 1, time: "Month", price: "5 OMR" },
    { id: 2, time: "3 Months", price: "15 OMR" },
    { id: 3, time: "6 Months", price: "30 OMR" },
    { id: 4, time: "Year", price: "60 OMR" },
  ];
  const [selectedDuration, setSelectedDuration] = useState(durationsList[0]);
  return (
    <div className="add-ad-model flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex items-center justify-between gap-3">
        <h1 className="text-black text-base font-medium">Add Ad</h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex items-center sm:flex-row flex-col gap-3">
          <div className="flex flex-col flex-1 gap-2">
            <label className="text-black text-sm font-normal">
              Ad category
            </label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              className="w-full"
            />
          </div>
          <div className="flex flex-col flex-1 gap-2">
            <label className="text-black text-sm font-normal">Media</label>
            <div className="flex items-center w-full">
              <div className="field flex flex-col w-full">
                <div
                  className="img-box flex items-center justify-center h-10 w-full bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                  onClick={() => document.getElementById("upload_img").click()}
                >
                  {img ? (
                    <img
                      alt="img"
                      src={URL.createObjectURL(img)}
                      className="h-full w-full overflow-hidden object-contain"
                    />
                  ) : (
                    <div className="flex items-center justify-between gap-3">
                      <div className="flex items-center justify-center w-4">
                        <AttachmentIcon />
                      </div>
                      <label className="text-xs w-max text-[#808080]">
                        Add Image & Video & GIF{" "}
                      </label>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    name="image"
                    id="upload_img"
                    className="hidden cleanbtn"
                    onChange={(e) => {
                      setImg(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">Title</label>
          <textarea
            type="text"
            placeholder="What’s happening? "
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm min-h-[120px] max-h-[120px]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">Title</label>
          <textarea
            type="text"
            placeholder="What’s happening? "
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm min-h-[180px] max-h-[180px]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">Ad duration</label>
          <div className="flex items-center gap-1">
            {durationsList.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-between flex-1 gap-1 p-3 rounded-lg border border-black/20 cursor-pointer ${
                  selectedDuration.id === item.id
                    ? "bg-themeColor text-white"
                    : "bg-transparent"
                }`}
                onClick={(e) => setSelectedDuration(item)}
              >
                <h3 className="text-[9px] font-light">{item.time}</h3>
                <h3 className="text-[9px] font-light">{item.price}</h3>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-center">
          <button className="button rounded-full !py-2 !px-10">Post</button>
        </div>
      </div>
    </div>
  );
};

export default AddAdModel;
