import React from "react";

function EditIcon3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.417 1.167H5.25c-2.917 0-4.083 1.166-4.083 4.083v3.5c0 2.917 1.166 4.083 4.083 4.083h3.5c2.917 0 4.083-1.166 4.083-4.083V7.583"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M9.357 1.762L4.76 6.358c-.175.175-.35.52-.385.77l-.25 1.756c-.094.636.355 1.08.99.992l1.757-.251c.245-.035.589-.21.77-.385l4.596-4.597c.794-.793 1.167-1.715 0-2.881-1.166-1.167-2.088-.794-2.881 0zM8.698 2.42a4.168 4.168 0 002.881 2.882"
      ></path>
    </svg>
  );
}

export default EditIcon3;
